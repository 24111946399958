const { decChangeScale, binaryAppendZero } = $numberMatrixing;

const UNSIGNED = true, SIGNED = false;

export const NameAddress = [
  { name: "runState", type: UNSIGNED, size: 16, address: { read: "2001", write: "" } }, // type true: unsigned , false signed,
  { name: "prechargePhase", type: UNSIGNED, size: 16, address: { read: "2002", write: "" }  },
  { name: "connectionState", type: UNSIGNED, size: 16, address: { read: "2003", write: "" }  },
  { name: "fault", type: UNSIGNED, size: 16, address: { read: "2004", write: "" }  },
  { name: "warning", type: UNSIGNED, size: 16, address: { read: "2005", write: "" }  },
  { name: "alarm", type: UNSIGNED, size: 16, address: { read: "2006", write: "" }  },
  { name: "criticalAlarm", type: UNSIGNED, size: 16, address: { read: "2007", write: "" }  },
  { name: "chargeDischargeState", type: UNSIGNED, size: 16, address: { read: "200D", write: "" }  },
  { name: "insulationValue", type: UNSIGNED, size: 16, address: { read: "2010", write: "" }  },
  { name: "positiveInsulationValue", type: UNSIGNED, size: 16, address: { read: "2011", write: "" }  },
  { name: "negativeInsulationValue", type: UNSIGNED, size: 16, address: { read: "2012", write: "" }  },
  { name: "maxChargeCurrent", type: UNSIGNED, size: 16, address: { read: "2013", write: "" }  },
  { name: "maxDischargeCurrent", type: UNSIGNED, size: 16, address: { read: "2014", write: "" }  },
  { name: "maxVolCellID", type: UNSIGNED, size: 16, address: { read: "2015", write: "" }  },
  { name: "maxCellVoltage", type: UNSIGNED, size: 16, address: { read: "2016", write: "" }  },
  { name: "minVolCellID", type: UNSIGNED, size: 16, address: { read: "2017", write: "" }  },
  { name: "minCellVoltage", type: UNSIGNED, size: 16, address: { read: "2018", write: "" }  },
  { name: "maxTemperatureCellID", type: UNSIGNED, size: 16, address: { read: "2019", write: "" }  },
  { name: "maxCellTemperature", type: SIGNED, size: 16, address: { read: "201A", write: "" }  },
  { name: "minTemperatureCellID", type: UNSIGNED, size: 16, address: { read: "201B", write: "" }  },
  { name: "minCellTemperature", type: SIGNED, size: 16, address: { read: "201C", write: "" }  },
  { name: "cellVoltage", type: UNSIGNED, size: 16, address: { read: "25FF", write: "" }  },
  { name: "cellTemperature", type: SIGNED, size: 16, address: { read: "27FF", write: "" }  },

  { name: "gwSoftVer1", type: UNSIGNED, size: 16, address: { read: "2810", write: "" }  },
  { name: "gwSoftVer2", type: UNSIGNED, size: 16, address: { read: "2811", write: "" }  },
  { name: "gwHardVer1", type: UNSIGNED, size: 16, address: { read: "2812", write: "" }  },
  { name: "gwHardVer2", type: UNSIGNED, size: 16, address: { read: "2813", write: "" }  },
  { name: "masterCnts", type: UNSIGNED, size: 16, address: { read: "2817", write: "" }  },
  { name: "projectType", type: UNSIGNED, size: 16, address: { read: "2837", write: "" }  },
  { name: "ipP1", type: UNSIGNED, size: 16, address: { read: "283B", write: "" }  },
  { name: "ipP2", type: UNSIGNED, size: 16, address: { read: "283C", write: "" }  },
  { name: "ipPort1", type: UNSIGNED, size: 16, address: { read: "283D", write: "" }  },
  { name: "ipPort2", type: UNSIGNED, size: 16, address: { read: "283E", write: "" }  },
  { name: "ipBakP1", type: UNSIGNED, size: 16, address: { read: "283F", write: "" }  },
  { name: "ipBakP2", type: UNSIGNED, size: 16, address: { read: "2840", write: "" }  },
  { name: "ipBakPort1", type: UNSIGNED, size: 16, address: { read: "2841", write: "" }  },
  { name: "ipBakPort2", type: UNSIGNED, size: 16, address: { read: "2842", write: "" }  },
  { name: "gwCanSpeed", type: UNSIGNED, size: 16, address: { read: "2843", write: "" }  },
  ///////
  { name: "lte", type: UNSIGNED, size: 16, address: { read: "280F", write: "" }  },
  // 根据 TODO#20240417-C-1 ，32 位数的两节全写成 UNSIGNED ，交给程序进行单独计算
  { name: "activeReporingTime1", type: UNSIGNED, size: 16, address: { read: "2800", write: "" }  },
  { name: "activeReporingTime2", type: UNSIGNED, size: 16, address: { read: "2801", write: "" }  },
  ////////////////////////////////
  // 主控基本信息
  { name: "voltageTotal", type: UNSIGNED, size: 16, address: { read: "3370", write: "" }  },
  { name: "auxPower", type: UNSIGNED, size: 16, address: { read: "3371", write: "" }  },
  { name: "soc", type: UNSIGNED, size: 16, address: { read: "3372", write: "" }  },
  { name: "soh", type: UNSIGNED, size: 16, address: { read: "3373", write: "" }  },
  { name: "sop", type: UNSIGNED, size: 16, address: { read: "3374", write: "" }  },
  { name: "socAvg", type: UNSIGNED, size: 16, address: { read: "3375", write: "" }  },
  { name: "highResistanceBATP", type: UNSIGNED, size: 16, address: { read: "3376", write: "" }  },
  { name: "highResistanceBATN", type: UNSIGNED, size: 16, address: { read: "3377", write: "" }  },
  { name: "HLSD_state", type: UNSIGNED, size: 16, address: { read: "3378", write: "" }  },
  { name: "ltc6811CommState", type: UNSIGNED, size: 64, address: { read: "3379", write: "" }  },
  // 337a ~ 337c
  { name: "workMode", type: UNSIGNED, size: 16, address: { read: "337D", write: "" }  },
  { name: "current", type: SIGNED, size: 16, address: { read: "337E", write: "" }  },
  { name: "maxVol", type: UNSIGNED, size: 16, address: { read: "337F", write: "" }  },
  { name: "maxVolNo", type: UNSIGNED, size: 16, address: { read: "3380", write: "" }  },
  { name: "minVol", type: UNSIGNED, size: 16, address: { read: "3381", write: "" }  },
  { name: "minVolNo", type: UNSIGNED, size: 16, address: { read: "3382", write: "" }  },
  { name: "maxTempNo", type: UNSIGNED, size: 16, address: { read: "3383", write: "" }  },
  { name: "minTempNo", type: UNSIGNED, size: 16, address: { read: "3384", write: "" }  },
  { name: "maxTemp", type: SIGNED, size: 16, address: { read: "3385", write: "" }  },
  { name: "minTemp", type: SIGNED, size: 16, address: { read: "3386", write: "" }  },
  { name: "avgVol", type: UNSIGNED, size: 16, address: { read: "3387", write: "" }  },
  { name: "chgSumKw1", type: UNSIGNED, size: 32, address: { read: "3388", write: "" }  },
  { name: "chgSumKw2", type: UNSIGNED, size: 32, address: { read: "3389", write: "" }  },
  { name: "dsgSumKw1", type: UNSIGNED, size: 32, address: { read: "338A", write: "" }  },
  { name: "dsgSumKw2", type: UNSIGNED, size: 32, address: { read: "338B", write: "" }  },
  { name: "reserved1", type: UNSIGNED, size: 32, address: { read: "338C", write: "" }  },
  // 338d
  { name: "reserved2", type: UNSIGNED, size: 32, address: { read: "338E", write: "" }  },
  // 338f ~ 339f
  // 接触器参数
  { name: "preChargeContactRatio", type: UNSIGNED, size: 16, address: { read : "3340", write: "3340"}},
  // 3341
  { name: "contactSingleTime", type: UNSIGNED, size: 16, address: { read: "3342", write: "3342" } },
  { name: "chargeContactSingleTime", type: UNSIGNED, size: 16, address: { read: "3343", write: "3343" } },
  ////////////////////////////////
  { name: "voltageMask", type: UNSIGNED, size: 32, address: { read: "33A0", write: "" }  },
  { name: "temperatureMask", type: UNSIGNED, size: 32, address: { read: "33A1", write: "" }  },
  ////////////////////////////////
  // 历史告警
  { name: "historyAlarm", type: UNSIGNED, size: 32, address: { read: "3600", write: "" }  },
  { name: "historyWarning", type: UNSIGNED, size: 32, address: { read: "3602", write: "" }  },
  { name: "historyCriticalAlarm", type: UNSIGNED, size: 32, address: { read: "3604", write: "" }  },
  ////////////////////////////////
  // 系统参数配置
  { name: "lecuAddr", type: UNSIGNED, size: 16, address: { read: "3300", write: "3300" } },
  { name: "batType", type: UNSIGNED, size: 16, address: { read: "3301", write: "3301" } },
  { name: "afeNum", type: UNSIGNED, size: 16, address: { read: "3302", write: "3302" } },
  { name: "shunt_I_offset", type: SIGNED, size: 16, address: { read: "3303", write: "3303" }, length: 8  },
  { name: "hwVer1", type: UNSIGNED, size: 16, address: { read: "3304", write: "" }  },
  { name: "hwVer2", type: UNSIGNED, size: 16, address: { read: "3305", write: "" }  },
  { name: "swVer1", type: UNSIGNED, size: 16, address: { read: "3306", write: "" }  },
  { name: "swVer2", type: UNSIGNED, size: 16, address: { read: "3307", write: "" }  },
  // 3308 ~ 332F
  { name: "ltc6811FilterRatio", type: UNSIGNED, size: 16, address: { read: "3330", write: "3330" } },
  { name: "mcuadFilterRatio", type: UNSIGNED, size: 16, address: { read: "3331", write: "3331" } },
  { name: "ad7706FilterRatio", type: UNSIGNED, size: 16, address: { read: "3332", write: "3332" } },
  // 3333
  { name: "staticDifference", type: UNSIGNED, size: 16, address: { read: "3334", write: "3334" } },
  { name: "staticThreshold", type: UNSIGNED, size: 16, address: { read: "3335", write: "3335" } },
  { name: "dynamicDifference", type: UNSIGNED, size: 16, address: { read: "3336", write: "3336" } },
  { name: "dynamicThreshold", type: UNSIGNED, size: 16, address: { read: "3337", write: "3337" } },
  { name: "maxChargeChannel", type: UNSIGNED, size: 16, address: { read: "3338", write: "3338" } },
  { name: "maxBalanceChannel", type: UNSIGNED, size: 16, address: { read: "3339", write: "3339" } },
  // 333a
  { name: "balanceMode", type: UNSIGNED, size: 16, address: { read: "333B", write: "333B" } },
  // 333c
  // 放电是否均衡
  { name: "balnaceDischarge", type: UNSIGNED, size: 16, address: { read: "333D", write: "333D" }  },
  // 333E
  // 333F
  // 3335
  // 3336
  { name: "staticEurrentError", type: UNSIGNED, size: 16, address: { read: "3347", write: "3347" } },
  // 3348 ~ 334b
  // 温控协议
  { name: "range", type: UNSIGNED, size: 16, address: { read: "334C", write: "334C" }  },
  { name: "batCellType", type: UNSIGNED, size: 16, address: { read: "334D", write: "334D" } },
  // 分流器参数
  { name: "shunt_I", type: UNSIGNED, size: 16, address: { read: "334E", write: "334E" }  },
  { name: "shunt_mV", type: UNSIGNED, size: 16, address: { read: "334F", write: "334F" }  },
  { name: "hsdxCfg0", type: UNSIGNED, size: 16, address: { read: "3350", write: "3350" } },
  { name: "hsdxCfg1", type: UNSIGNED, size: 16, address: { read: "3351", write: "3351" } },
  { name: "hsdxCfg2", type: UNSIGNED, size: 16, address: { read: "3352", write: "3352" } },
  { name: "hsdxCfg3", type: UNSIGNED, size: 16, address: { read: "3353", write: "3353" } },
  { name: "hsdxCfg4", type: UNSIGNED, size: 16, address: { read: "3354", write: "3354" } },
  // 3355 ~ 3357
  // 充放电流参数
  // 放电电流门限值（允许请求放电告警电流值）
  { name: "ipQueryDsgTHD", type: UNSIGNED, size: 16, address: { read: "3358", write: "3358" }  },
  // 充电电流门限值（允许请求充电最大电流切断值）
  { name: "ipQueryChgCutTHD", type: UNSIGNED, size: 16, address: { read: "3359", write: "3359" }  },
  { name: "lecuNewAddr", type: UNSIGNED, size: 16, address: { read: "335A", write: "335A" } },
  { name: "hwType", type: UNSIGNED, size: 16, address: { read: "335B", write: "335B" } },
  { name: "initSoc", type: UNSIGNED, size: 16, address: { read: "335C", write: "335C" } },
  // 335D
  { name: "controlBz", type: UNSIGNED, size: 16, address: { read: "335E", write: "335E" } },
  { name: "vanStart", type: UNSIGNED, size: 16, address: { read: "335F", write: "335F" } },
  { name: "vanStop", type: UNSIGNED, size: 16, address: { read: "3360", write: "3360" } },
  { name: "hotStart", type: UNSIGNED, size: 16, address: { read: "336A", write: "336A" } },
  { name: "hotStop", type: UNSIGNED, size: 16, address: { read: "336B", write: "336B" } },
  // 国标最大充电流 + 国标充电报文延迟 + COM BAUD
  { name: "gbChgMaxCur_ComBaud", type: UNSIGNED, size: 16, address: { read: "336C", write: "336C" }  },
  ////////////////////////////////
  { name: "chgProtocol", type: UNSIGNED, size: 16, address: { read: "3361", write: "3361" } },
  { name: "tempAndNumRes", type: UNSIGNED, size: 16, address: { read: "3362", write: "3362" } },
  { name: "canConfig", type: UNSIGNED, size: 16, address: { read: "3364", write: "3364" } },
  { name: "canBaud", type: UNSIGNED, size: 16, address: { read: "3363", write: "3363" }  },
  { name: "systemVolumetric", type: UNSIGNED, size: 16, address: { read: "3344", write: "3344" } },
  { name: "workVoltage", type: UNSIGNED, size: 16, address: { read: "3365", write: "3365" } },
  { name: "vcu_soh", type: UNSIGNED, size: 16, address: { read: "3366", write: "3366" } },
  { name: "sohCycle", type: UNSIGNED, size: 16, address: { read: "3367", write: "3367" } },
  { name: "speepDelay_limitCurrent", type: UNSIGNED, size: 16, address: { read: "3368", write: "3368" } },
  { name: "pbIoset_tranProtocol", type: UNSIGNED, size: 16, address: { read: "3369", write: "3369" } },
  // { name: "alarmInterval0", type: UNSIGNED, size: 16, address: { read: "3349", write: "3349" } },
  // { name: "alarmInterval1", type: UNSIGNED, size: 16, address: { read: "334A", write: "334A" } },
  // { name: "alarmInterval2", type: UNSIGNED, size: 16, address: { read: "334B", write: "334B" } },
  ////////////////////////////////
  // 一级告警
  // 总体过压保护门限  
  { name: "alarm_Vtotal_OverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "3067", write: "3067" } },
  { name: "alarm_Vtotal_OverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3068", write: "3068" } },
  { name: "alarm_Vtotal_OverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3069", write: "3069" } },
  { name: "alarm_Vtotal_OverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "306A", write: "306A" } },
  // 总体欠压保护门限
  { name: "alarm_Vtotal_UnderPretTHD_1", type: UNSIGNED, size: 16, address: { read: "306B", write: "306B" } },
  { name: "alarm_Vtotal_UnderPretTHD_2", type: UNSIGNED, size: 16, address: { read: "306C", write: "306C" } },
  { name: "alarm_Vtotal_UnderPretTHD_3", type: UNSIGNED, size: 16, address: { read: "306D", write: "306D" } },
  { name: "alarm_Vtotal_UnderPretTHD_4", type: UNSIGNED, size: 16, address: { read: "306E", write: "306E" } },
  // 单体过压保护门限
  { name: "alarm_Vcell_OverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "306F", write: "306F" } },
  { name: "alarm_Vcell_OverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3070", write: "3070" } },
  { name: "alarm_Vcell_OverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3071", write: "3071" } },
  { name: "alarm_Vcell_OverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "3072", write: "3072" } },
  // 单体欠压保护门限
  { name: "alarm_Vcell_UnderPretTHD_1", type: UNSIGNED, size: 16, address: { read: "3073", write: "3073" } },
  { name: "alarm_Vcell_UnderPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3074", write: "3074" } },
  { name: "alarm_Vcell_UnderPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3075", write: "3075" } },
  { name: "alarm_Vcell_UnderPretTHD_4", type: UNSIGNED, size: 16, address: { read: "3076", write: "3076" } },
  // 4                                       Vdiff_HOverPretTHD[4]
  { name: "alarm_Vdiff_HOverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "3077", write: "3077" } },
  { name: "alarm_Vdiff_HOverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3078", write: "3078" } },
  { name: "alarm_Vdiff_HOverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3079", write: "3079" } },
  { name: "alarm_Vdiff_HOverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "307A", write: "307A" } },
  // 5                                             Vdiff_MOverPretTHD[4]
  { name: "alarm_Vdiff_MOverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "307B", write: "307B" } },
  { name: "alarm_Vdiff_MOverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "307C", write: "307C" } },
  { name: "alarm_Vdiff_MOverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "307D", write: "307D" } },
  { name: "alarm_Vdiff_MOverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "307E", write: "307E" } },
  // 高中低电压极差保护门限                             Vdiff_LOverPretTHD[4]
  { name: "alarm_Vdiff_LOverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "307F", write: "307F" } },
  { name: "alarm_Vdiff_LOverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3080", write: "3080" } },
  { name: "alarm_Vdiff_LOverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3081", write: "3081" } },
  { name: "alarm_Vdiff_LOverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "3082", write: "3082" } },
  // 辅助电压欠压保护门限                                    Vauxpower_UnderPretTHD[4]
  { name: "alarm_Vauxpower_UnderPretTHD_1", type: UNSIGNED, size: 16, address: { read: "3083", write: "3083" } },
  { name: "alarm_Vauxpower_UnderPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3084", write: "3084" } },
  { name: "alarm_Vauxpower_UnderPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3085", write: "3085" } },
  { name: "alarm_Vauxpower_UnderPretTHD_4", type: UNSIGNED, size: 16, address: { read: "3086", write: "3086" } },
  // 充电过流保护门限                                      ICharge_OverPretTHD[4]
  { name: "alarm_ICharge_OverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "3087", write: "3087" } },
  { name: "alarm_ICharge_OverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3088", write: "3088" } },
  { name: "alarm_ICharge_OverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3089", write: "3089" } },
  { name: "alarm_ICharge_OverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "308A", write: "308A" } },
  // 放电过流保护门限                             IDischarge_OverPretTHD[4]
  { name: "alarm_IDischarge_OverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "308B", write: "308B" } },
  { name: "alarm_IDischarge_OverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "308C", write: "308C" } },
  { name: "alarm_IDischarge_OverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "308D", write: "308D" } },
  { name: "alarm_IDischarge_OverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "308E", write: "308E" } },
  // 返充电过流保护门限                  FeedCharge_OverPretTHD[4]
  { name: "alarm_FeedCharge_OverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "308F", write: "308F" } },
  { name: "alarm_FeedCharge_OverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3090", write: "3090" } },
  { name: "alarm_FeedCharge_OverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3091", write: "3091" } },
  { name: "alarm_FeedCharge_OverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "3092", write: "3092" } },
  // SOC过低保护门限                                     SOC_UnderPretTHD[4]
  { name: "alarm_SOC_UnderPretTHD_1", type: UNSIGNED, size: 16, address: { read: "3093", write: "3093" } },
  { name: "alarm_SOC_UnderPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3094", write: "3094" } },
  { name: "alarm_SOC_UnderPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3095", write: "3095" } },
  { name: "alarm_SOC_UnderPretTHD_4", type: UNSIGNED, size: 16, address: { read: "3096", write: "3096" } },
  // SOH过低保护门限                                    SOH_UnderPretTHD[4]
  { name: "alarm_SOH_UnderPretTHD_1", type: UNSIGNED, size: 16, address: { read: "3097", write: "3097" } },
  { name: "alarm_SOH_UnderPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3098", write: "3098" } },
  { name: "alarm_SOH_UnderPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3099", write: "3099" } },
  { name: "alarm_SOH_UnderPretTHD_4", type: UNSIGNED, size: 16, address: { read: "309A", write: "309A" } },
  // 接触器过温切断阈值                                       T_relay_OV_THD[4]
  { name: "alarm_T_relay_OV_THD_1", type: SIGNED, size: 16, address: { read: "309B", write: "309B" } },
  { name: "alarm_T_relay_OV_THD_2", type: SIGNED, size: 16, address: { read: "309C", write: "309C" } },
  { name: "alarm_T_relay_OV_THD_3", type: SIGNED, size: 16, address: { read: "309D", write: "309D" } },
  { name: "alarm_T_relay_OV_THD_4", type: SIGNED, size: 16, address: { read: "309E", write: "309E" } },
  // 充电过温阈值                                        TChg_OV_THD[4]
  { name: "alarm_TChg_OV_THD_1", type: SIGNED, size: 16, address: { read: "309F", write: "309F" } },
  { name: "alarm_TChg_OV_THD_2", type: SIGNED, size: 16, address: { read: "30A0", write: "30A0" } },
  { name: "alarm_TChg_OV_THD_3", type: SIGNED, size: 16, address: { read: "30A1", write: "30A1" } },
  { name: "alarm_TChg_OV_THD_4", type: SIGNED, size: 16, address: { read: "30A2", write: "30A2" } },
  // 放电过温阈值                                          TDsg_OV_THD[4]
  { name: "alarm_TDsg_OV_THD_1", type: SIGNED, size: 16, address: { read: "30A3", write: "30A3" } },
  { name: "alarm_TDsg_OV_THD_2", type: SIGNED, size: 16, address: { read: "30A4", write: "30A4" } },
  { name: "alarm_TDsg_OV_THD_3", type: SIGNED, size: 16, address: { read: "30A5", write: "30A5" } },
  { name: "alarm_TDsg_OV_THD_4", type: SIGNED, size: 16, address: { read: "30A6", write: "30A6" } },
  // 充电欠温阈值                                        TChg_UV_THD[4]
  { name: "alarm_TChg_UV_THD_1", type: SIGNED, size: 16, address: { read: "30A7", write: "30A7" } },
  { name: "alarm_TChg_UV_THD_2", type: SIGNED, size: 16, address: { read: "30A8", write: "30A8" } },
  { name: "alarm_TChg_UV_THD_3", type: SIGNED, size: 16, address: { read: "30A9", write: "30A9" } },
  { name: "alarm_TChg_UV_THD_4", type: SIGNED, size: 16, address: { read: "30AA", write: "30AA" } },
  // 放电欠温阈值                                   TDsg_UV_THD[4]
  { name: "alarm_TDsg_UV_THD_1", type: SIGNED, size: 16, address: { read: "30AB", write: "30AB" } },
  { name: "alarm_TDsg_UV_THD_2", type: SIGNED, size: 16, address: { read: "30AC", write: "30AC" } },
  { name: "alarm_TDsg_UV_THD_3", type: SIGNED, size: 16, address: { read: "30AD", write: "30AD" } },
  { name: "alarm_TDsg_UV_THD_4", type: SIGNED, size: 16, address: { read: "30AE", write: "30AE" } },
  // 电池温差过大                                        Tdiff_cell_OV_THD[4]
  { name: "alarm_Tdiff_cell_OV_THD_1", type: SIGNED, size: 16, address: { read: "30AF", write: "30AF" } },
  { name: "alarm_Tdiff_cell_OV_THD_2", type: SIGNED, size: 16, address: { read: "30B0", write: "30B0" } },
  { name: "alarm_Tdiff_cell_OV_THD_3", type: SIGNED, size: 16, address: { read: "30B1", write: "30B1" } },
  { name: "alarm_Tdiff_cell_OV_THD_4", type: SIGNED, size: 16, address: { read: "30B2", write: "30B2" } },
  // 均衡过温保护门限                                Tbalance_OverPretTHD[4]
  { name: "alarm_Tbalance_OverPretTHD_1", type: SIGNED, size: 16, address: { read: "30B3", write: "30B3" } },
  { name: "alarm_Tbalance_OverPretTHD_2", type: SIGNED, size: 16, address: { read: "30B4", write: "30B4" } },
  { name: "alarm_Tbalance_OverPretTHD_3", type: SIGNED, size: 16, address: { read: "30B5", write: "30B5" } },
  { name: "alarm_Tbalance_OverPretTHD_4", type: SIGNED, size: 16, address: { read: "30B6", write: "30B6" } },
  // 绝缘对地阻抗过低保护门限                              high_resistance_UnderPretTHD[4]
  { name: "alarm_high_resistance_UnderPretTHD_1", type: UNSIGNED, size: 16, address: { read: "30B7", write: "30B7" } },
  { name: "alarm_high_resistance_UnderPretTHD_2", type: UNSIGNED, size: 16, address: { read: "30B8", write: "30B8" } },
  { name: "alarm_high_resistance_UnderPretTHD_3", type: UNSIGNED, size: 16, address: { read: "30B9", write: "30B9" } },
  { name: "alarm_high_resistance_UnderPretTHD_4", type: UNSIGNED, size: 16, address: { read: "30BA", write: "30BA" } },
  // 总压采样异常                                      Vtotal_DiffPretTHD[4]
  { name: "alarm_Vtotal_DiffPretTHD_1", type: UNSIGNED, size: 16, address: { read: "30BB", write: "30BB" } },
  { name: "alarm_Vtotal_DiffPretTHD_2", type: UNSIGNED, size: 16, address: { read: "30BC", write: "30BC" } },
  { name: "alarm_Vtotal_DiffPretTHD_3", type: UNSIGNED, size: 16, address: { read: "30BD", write: "30BD" } },
  { name: "alarm_Vtotal_DiffPretTHD_4", type: UNSIGNED, size: 16, address: { read: "30BE", write: "30BE" } },
  // 静置过温阈值                                            TSLIENT_OV_THD[4]
  { name: "alarm_TSLIENT_OV_THD_1", type: SIGNED, size: 16, address: { read: "30BF", write: "30BF" } },
  { name: "alarm_TSLIENT_OV_THD_2", type: SIGNED, size: 16, address: { read: "30C0", write: "30C0" } },
  { name: "alarm_TSLIENT_OV_THD_3", type: SIGNED, size: 16, address: { read: "30C1", write: "30C1" } },
  { name: "alarm_TSLIENT_OV_THD_4", type: SIGNED, size: 16, address: { read: "30C2", write: "30C2" } },
  // 静置欠温阈值                                             TSLIENT_UV_THD[4]
  { name: "alarm_TSLIENT_UV_THD_1", type: SIGNED, size: 16, address: { read: "30C3", write: "30C3" } },
  { name: "alarm_TSLIENT_UV_THD_2", type: SIGNED, size: 16, address: { read: "30C4", write: "30C4" } },
  { name: "alarm_TSLIENT_UV_THD_3", type: SIGNED, size: 16, address: { read: "30C5", write: "30C5" } },
  { name: "alarm_TSLIENT_UV_THD_4", type: SIGNED, size: 16, address: { read: "30C6", write: "30C6" } },
  // 二级告警
  // 总体过压保护门限  
  { name: "warning_Vtotal_OverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "30C7", write: "30C7" } },
  { name: "warning_Vtotal_OverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "30C8", write: "30C8" } },
  { name: "warning_Vtotal_OverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "30C9", write: "30C9" } },
  { name: "warning_Vtotal_OverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "30CA", write: "30CA" } },
  // 总体欠压保护门限
  { name: "warning_Vtotal_UnderPretTHD_1", type: UNSIGNED, size: 16, address: { read: "30CB", write: "30CB" } },
  { name: "warning_Vtotal_UnderPretTHD_2", type: UNSIGNED, size: 16, address: { read: "30CC", write: "30CC" } },
  { name: "warning_Vtotal_UnderPretTHD_3", type: UNSIGNED, size: 16, address: { read: "30CD", write: "30CD" } },
  { name: "warning_Vtotal_UnderPretTHD_4", type: UNSIGNED, size: 16, address: { read: "30CE", write: "30CE" } },
  // 单体过压保护门限
  { name: "warning_Vcell_OverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "30CF", write: "30CF" } },
  { name: "warning_Vcell_OverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "30D0", write: "30D0" } },
  { name: "warning_Vcell_OverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "30D1", write: "30D1" } },
  { name: "warning_Vcell_OverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "30D2", write: "30D2" } },
  // 单体欠压保护门限
  { name: "warning_Vcell_UnderPretTHD_1", type: UNSIGNED, size: 16, address: { read: "30D3", write: "30D3" } },
  { name: "warning_Vcell_UnderPretTHD_2", type: UNSIGNED, size: 16, address: { read: "30D4", write: "30D4" } },
  { name: "warning_Vcell_UnderPretTHD_3", type: UNSIGNED, size: 16, address: { read: "30D5", write: "30D5" } },
  { name: "warning_Vcell_UnderPretTHD_4", type: UNSIGNED, size: 16, address: { read: "30D6", write: "30D6" } },
  // 4                                       Vdiff_HOverPretTHD[4]
  { name: "warning_Vdiff_HOverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "30D7", write: "30D7" } },
  { name: "warning_Vdiff_HOverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "30D8", write: "30D8" } },
  { name: "warning_Vdiff_HOverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "30D9", write: "30D9" } },
  { name: "warning_Vdiff_HOverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "30DA", write: "30DA" } },
  // 5                                             Vdiff_MOverPretTHD[4]
  { name: "warning_Vdiff_MOverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "30DB", write: "30DB" } },
  { name: "warning_Vdiff_MOverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "30DC", write: "30DC" } },
  { name: "warning_Vdiff_MOverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "30DD", write: "30DD" } },
  { name: "warning_Vdiff_MOverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "30DE", write: "30DE" } },
  // 高中低电压极差保护门限                             Vdiff_LOverPretTHD[4]
  { name: "warning_Vdiff_LOverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "30DF", write: "30DF" } },
  { name: "warning_Vdiff_LOverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "30E0", write: "30E0" } },
  { name: "warning_Vdiff_LOverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "30E1", write: "30E1" } },
  { name: "warning_Vdiff_LOverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "30E2", write: "30E2" } },
  // 辅助电压欠压保护门限                                    Vauxpower_UnderPretTHD[4]
  { name: "warning_Vauxpower_UnderPretTHD_1", type: UNSIGNED, size: 16, address: { read: "30E3", write: "30E3" } },
  { name: "warning_Vauxpower_UnderPretTHD_2", type: UNSIGNED, size: 16, address: { read: "30E4", write: "30E4" } },
  { name: "warning_Vauxpower_UnderPretTHD_3", type: UNSIGNED, size: 16, address: { read: "30E5", write: "30E5" } },
  { name: "warning_Vauxpower_UnderPretTHD_4", type: UNSIGNED, size: 16, address: { read: "30E6", write: "30E6" } },
  // 充电过流保护门限                                      ICharge_OverPretTHD[4]
  { name: "warning_ICharge_OverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "30E7", write: "30E7" } },
  { name: "warning_ICharge_OverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "30E8", write: "30E8" } },
  { name: "warning_ICharge_OverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "30E9", write: "30E9" } },
  { name: "warning_ICharge_OverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "30EA", write: "30EA" } },
  // 放电过流保护门限                             IDischarge_OverPretTHD[4]
  { name: "warning_IDischarge_OverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "30EB", write: "30EB" } },
  { name: "warning_IDischarge_OverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "30EC", write: "30EC" } },
  { name: "warning_IDischarge_OverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "30ED", write: "30ED" } },
  { name: "warning_IDischarge_OverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "30EE", write: "30EE" } },
  // 返充电过流保护门限                  FeedCharge_OverPretTHD[4]
  { name: "warning_FeedCharge_OverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "30EF", write: "30EF" } },
  { name: "warning_FeedCharge_OverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "30F0", write: "30F0" } },
  { name: "warning_FeedCharge_OverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "30F1", write: "30F1" } },
  { name: "warning_FeedCharge_OverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "30F2", write: "30F2" } },
  // SOC过低保护门限                                     SOC_UnderPretTHD[4]
  { name: "warning_SOC_UnderPretTHD_1", type: UNSIGNED, size: 16, address: { read: "30F3", write: "30F3" } },
  { name: "warning_SOC_UnderPretTHD_2", type: UNSIGNED, size: 16, address: { read: "30F4", write: "30F4" } },
  { name: "warning_SOC_UnderPretTHD_3", type: UNSIGNED, size: 16, address: { read: "30F5", write: "30F5" } },
  { name: "warning_SOC_UnderPretTHD_4", type: UNSIGNED, size: 16, address: { read: "30F6", write: "30F6" } },
  // SOH过低保护门限                                    SOH_UnderPretTHD[4]
  { name: "warning_SOH_UnderPretTHD_1", type: UNSIGNED, size: 16, address: { read: "30F7", write: "30F7" } },
  { name: "warning_SOH_UnderPretTHD_2", type: UNSIGNED, size: 16, address: { read: "30F8", write: "30F8" } },
  { name: "warning_SOH_UnderPretTHD_3", type: UNSIGNED, size: 16, address: { read: "30F9", write: "30F9" } },
  { name: "warning_SOH_UnderPretTHD_4", type: UNSIGNED, size: 16, address: { read: "30FA", write: "30FA" } },
  // 接触器过温切断阈值                                       T_relay_OV_THD[4]
  { name: "warning_T_relay_OV_THD_1", type: SIGNED, size: 16, address: { read: "30FB", write: "30FB" } },
  { name: "warning_T_relay_OV_THD_2", type: SIGNED, size: 16, address: { read: "30FC", write: "30FC" } },
  { name: "warning_T_relay_OV_THD_3", type: SIGNED, size: 16, address: { read: "30FD", write: "30FD" } },
  { name: "warning_T_relay_OV_THD_4", type: SIGNED, size: 16, address: { read: "30FE", write: "30FE" } },
  // 充电过温阈值                                        TChg_OV_THD[4]
  { name: "warning_TChg_OV_THD_1", type: SIGNED, size: 16, address: { read: "30FF", write: "30FF" } },
  { name: "warning_TChg_OV_THD_2", type: SIGNED, size: 16, address: { read: "3100", write: "3100" } },
  { name: "warning_TChg_OV_THD_3", type: SIGNED, size: 16, address: { read: "3101", write: "3101" } },
  { name: "warning_TChg_OV_THD_4", type: SIGNED, size: 16, address: { read: "3102", write: "3102" } },
  // 放电过温阈值                                          TDsg_OV_THD[4]
  { name: "warning_TDsg_OV_THD_1", type: SIGNED, size: 16, address: { read: "3103", write: "3103" } },
  { name: "warning_TDsg_OV_THD_2", type: SIGNED, size: 16, address: { read: "3104", write: "3104" } },
  { name: "warning_TDsg_OV_THD_3", type: SIGNED, size: 16, address: { read: "3105", write: "3105" } },
  { name: "warning_TDsg_OV_THD_4", type: SIGNED, size: 16, address: { read: "3106", write: "3106" } },
  // 充电欠温阈值                                        TChg_UV_THD[4]
  { name: "warning_TChg_UV_THD_1", type: SIGNED, size: 16, address: { read: "3107", write: "3107" } },
  { name: "warning_TChg_UV_THD_2", type: SIGNED, size: 16, address: { read: "3108", write: "3108" } },
  { name: "warning_TChg_UV_THD_3", type: SIGNED, size: 16, address: { read: "3109", write: "3109" } },
  { name: "warning_TChg_UV_THD_4", type: SIGNED, size: 16, address: { read: "310A", write: "310A" } },
  // 放电欠温阈值                                   TDsg_UV_THD[4]
  { name: "warning_TDsg_UV_THD_1", type: SIGNED, size: 16, address: { read: "310B", write: "310B" } },
  { name: "warning_TDsg_UV_THD_2", type: SIGNED, size: 16, address: { read: "310C", write: "310C" } },
  { name: "warning_TDsg_UV_THD_3", type: SIGNED, size: 16, address: { read: "310D", write: "310D" } },
  { name: "warning_TDsg_UV_THD_4", type: SIGNED, size: 16, address: { read: "310E", write: "310E" } },
  // 电池温差过大                                        Tdiff_cell_OV_THD[4]
  { name: "warning_Tdiff_cell_OV_THD_1", type: SIGNED, size: 16, address: { read: "310F", write: "310F" } },
  { name: "warning_Tdiff_cell_OV_THD_2", type: SIGNED, size: 16, address: { read: "3110", write: "3110" } },
  { name: "warning_Tdiff_cell_OV_THD_3", type: SIGNED, size: 16, address: { read: "3111", write: "3111" } },
  { name: "warning_Tdiff_cell_OV_THD_4", type: SIGNED, size: 16, address: { read: "3112", write: "3112" } },
  // 均衡过温保护门限                                Tbalance_OverPretTHD[4]
  { name: "warning_Tbalance_OverPretTHD_1", type: SIGNED, size: 16, address: { read: "3113", write: "3113" } },
  { name: "warning_Tbalance_OverPretTHD_2", type: SIGNED, size: 16, address: { read: "3114", write: "3114" } },
  { name: "warning_Tbalance_OverPretTHD_3", type: SIGNED, size: 16, address: { read: "3115", write: "3115" } },
  { name: "warning_Tbalance_OverPretTHD_4", type: SIGNED, size: 16, address: { read: "3116", write: "3116" } },
  // 绝缘对地阻抗过低保护门限                              high_resistance_UnderPretTHD[4]
  { name: "warning_high_resistance_UnderPretTHD_1", type: UNSIGNED, size: 16, address: { read: "3117", write: "3117" } },
  { name: "warning_high_resistance_UnderPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3118", write: "3118" } },
  { name: "warning_high_resistance_UnderPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3119", write: "3119" } },
  { name: "warning_high_resistance_UnderPretTHD_4", type: UNSIGNED, size: 16, address: { read: "311A", write: "311A" } },
  // 总压采样异常                                      Vtotal_DiffPretTHD[4]
  { name: "warning_Vtotal_DiffPretTHD_1", type: UNSIGNED, size: 16, address: { read: "311B", write: "311B" } },
  { name: "warning_Vtotal_DiffPretTHD_2", type: UNSIGNED, size: 16, address: { read: "311C", write: "311C" } },
  { name: "warning_Vtotal_DiffPretTHD_3", type: UNSIGNED, size: 16, address: { read: "311D", write: "311D" } },
  { name: "warning_Vtotal_DiffPretTHD_4", type: UNSIGNED, size: 16, address: { read: "311E", write: "311E" } },
  // 静置过温阈值                                            TSLIENT_OV_THD[4]
  { name: "warning_TSLIENT_OV_THD_1", type: SIGNED, size: 16, address: { read: "311F", write: "311F" } },
  { name: "warning_TSLIENT_OV_THD_2", type: SIGNED, size: 16, address: { read: "3120", write: "3120" } },
  { name: "warning_TSLIENT_OV_THD_3", type: SIGNED, size: 16, address: { read: "3121", write: "3121" } },
  { name: "warning_TSLIENT_OV_THD_4", type: SIGNED, size: 16, address: { read: "3122", write: "3122" } },
  // 静置欠温阈值                                             TSLIENT_UV_THD[4]
  { name: "warning_TSLIENT_UV_THD_1", type: SIGNED, size: 16, address: { read: "3123", write: "3123" } },
  { name: "warning_TSLIENT_UV_THD_2", type: SIGNED, size: 16, address: { read: "3124", write: "3124" } },
  { name: "warning_TSLIENT_UV_THD_3", type: SIGNED, size: 16, address: { read: "3125", write: "3125" } },
  { name: "warning_TSLIENT_UV_THD_4", type: SIGNED, size: 16, address: { read: "3126", write: "3126" } },
  // 三级告警
  // 总体过压保护门限  
  { name: "criticalAlarm_Vtotal_OverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "3127", write: "3127" } },
  { name: "criticalAlarm_Vtotal_OverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3128", write: "3128" } },
  { name: "criticalAlarm_Vtotal_OverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3129", write: "3129" } },
  { name: "criticalAlarm_Vtotal_OverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "312A", write: "312A" } },
  // 总体欠压保护门限
  { name: "criticalAlarm_Vtotal_UnderPretTHD_1", type: UNSIGNED, size: 16, address: { read: "312B", write: "312B" } },
  { name: "criticalAlarm_Vtotal_UnderPretTHD_2", type: UNSIGNED, size: 16, address: { read: "312C", write: "312C" } },
  { name: "criticalAlarm_Vtotal_UnderPretTHD_3", type: UNSIGNED, size: 16, address: { read: "312D", write: "312D" } },
  { name: "criticalAlarm_Vtotal_UnderPretTHD_4", type: UNSIGNED, size: 16, address: { read: "312E", write: "312E" } },
  // 单体过压保护门限
  { name: "criticalAlarm_Vcell_OverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "312F", write: "312F" } },
  { name: "criticalAlarm_Vcell_OverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3130", write: "3130" } },
  { name: "criticalAlarm_Vcell_OverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3131", write: "3131" } },
  { name: "criticalAlarm_Vcell_OverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "3132", write: "3132" } },
  // 单体欠压保护门限
  { name: "criticalAlarm_Vcell_UnderPretTHD_1", type: UNSIGNED, size: 16, address: { read: "3133", write: "3133" } },
  { name: "criticalAlarm_Vcell_UnderPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3134", write: "3134" } },
  { name: "criticalAlarm_Vcell_UnderPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3135", write: "3135" } },
  { name: "criticalAlarm_Vcell_UnderPretTHD_4", type: UNSIGNED, size: 16, address: { read: "3136", write: "3136" } },
  // 4                                       Vdiff_HOverPretTHD[4]
  { name: "criticalAlarm_Vdiff_HOverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "3137", write: "3137" } },
  { name: "criticalAlarm_Vdiff_HOverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3138", write: "3138" } },
  { name: "criticalAlarm_Vdiff_HOverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3139", write: "3139" } },
  { name: "criticalAlarm_Vdiff_HOverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "313A", write: "313A" } },
  // 5                                             Vdiff_MOverPretTHD[4]
  { name: "criticalAlarm_Vdiff_MOverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "313B", write: "313B" } },
  { name: "criticalAlarm_Vdiff_MOverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "313C", write: "313C" } },
  { name: "criticalAlarm_Vdiff_MOverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "313D", write: "313D" } },
  { name: "criticalAlarm_Vdiff_MOverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "313E", write: "313E" } },
  // 高中低电压极差保护门限                             Vdiff_LOverPretTHD[4]
  { name: "criticalAlarm_Vdiff_LOverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "313F", write: "313F" } },
  { name: "criticalAlarm_Vdiff_LOverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3140", write: "3140" } },
  { name: "criticalAlarm_Vdiff_LOverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3141", write: "3141" } },
  { name: "criticalAlarm_Vdiff_LOverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "3142", write: "3142" } },
  // 辅助电压欠压保护门限                                    Vauxpower_UnderPretTHD[4]
  { name: "criticalAlarm_Vauxpower_UnderPretTHD_1", type: UNSIGNED, size: 16, address: { read: "3143", write: "3143" } },
  { name: "criticalAlarm_Vauxpower_UnderPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3144", write: "3144" } },
  { name: "criticalAlarm_Vauxpower_UnderPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3145", write: "3145" } },
  { name: "criticalAlarm_Vauxpower_UnderPretTHD_4", type: UNSIGNED, size: 16, address: { read: "3146", write: "3146" } },
  // 充电过流保护门限                                      ICharge_OverPretTHD[4]
  { name: "criticalAlarm_ICharge_OverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "3147", write: "3147" } },
  { name: "criticalAlarm_ICharge_OverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3148", write: "3148" } },
  { name: "criticalAlarm_ICharge_OverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3149", write: "3149" } },
  { name: "criticalAlarm_ICharge_OverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "314A", write: "314A" } },
  // 放电过流保护门限                             IDischarge_OverPretTHD[4]
  { name: "criticalAlarm_IDischarge_OverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "314B", write: "314B" } },
  { name: "criticalAlarm_IDischarge_OverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "314C", write: "314C" } },
  { name: "criticalAlarm_IDischarge_OverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "314D", write: "314D" } },
  { name: "criticalAlarm_IDischarge_OverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "314E", write: "314E" } },
  // 返充电过流保护门限                  FeedCharge_OverPretTHD[4]
  { name: "criticalAlarm_FeedCharge_OverPretTHD_1", type: UNSIGNED, size: 16, address: { read: "314F", write: "314F" } },
  { name: "criticalAlarm_FeedCharge_OverPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3150", write: "3150" } },
  { name: "criticalAlarm_FeedCharge_OverPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3151", write: "3151" } },
  { name: "criticalAlarm_FeedCharge_OverPretTHD_4", type: UNSIGNED, size: 16, address: { read: "3152", write: "3152" } },
  // SOC过低保护门限                                     SOC_UnderPretTHD[4]
  { name: "criticalAlarm_SOC_UnderPretTHD_1", type: UNSIGNED, size: 16, address: { read: "3153", write: "3153" } },
  { name: "criticalAlarm_SOC_UnderPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3154", write: "3154" } },
  { name: "criticalAlarm_SOC_UnderPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3155", write: "3155" } },
  { name: "criticalAlarm_SOC_UnderPretTHD_4", type: UNSIGNED, size: 16, address: { read: "3156", write: "3156" } },
  // SOH过低保护门限                                    SOH_UnderPretTHD[4]
  { name: "criticalAlarm_SOH_UnderPretTHD_1", type: UNSIGNED, size: 16, address: { read: "3157", write: "3157" } },
  { name: "criticalAlarm_SOH_UnderPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3158", write: "3158" } },
  { name: "criticalAlarm_SOH_UnderPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3159", write: "3159" } },
  { name: "criticalAlarm_SOH_UnderPretTHD_4", type: UNSIGNED, size: 16, address: { read: "315A", write: "315A" } },
  // 接触器过温切断阈值                                       T_relay_OV_THD[4]
  { name: "criticalAlarm_T_relay_OV_THD_1", type: SIGNED, size: 16, address: { read: "315B", write: "315B" } },
  { name: "criticalAlarm_T_relay_OV_THD_2", type: SIGNED, size: 16, address: { read: "315C", write: "315C" } },
  { name: "criticalAlarm_T_relay_OV_THD_3", type: SIGNED, size: 16, address: { read: "315D", write: "315D" } },
  { name: "criticalAlarm_T_relay_OV_THD_4", type: SIGNED, size: 16, address: { read: "315E", write: "315E" } },
  // 充电过温阈值                                        TChg_OV_THD[4]
  { name: "criticalAlarm_TChg_OV_THD_1", type: SIGNED, size: 16, address: { read: "315F", write: "315F" } },
  { name: "criticalAlarm_TChg_OV_THD_2", type: SIGNED, size: 16, address: { read: "3160", write: "3160" } },
  { name: "criticalAlarm_TChg_OV_THD_3", type: SIGNED, size: 16, address: { read: "3161", write: "3161" } },
  { name: "criticalAlarm_TChg_OV_THD_4", type: SIGNED, size: 16, address: { read: "3162", write: "3162" } },
  // 放电过温阈值                                          TDsg_OV_THD[4]
  { name: "criticalAlarm_TDsg_OV_THD_1", type: SIGNED, size: 16, address: { read: "3163", write: "3163" } },
  { name: "criticalAlarm_TDsg_OV_THD_2", type: SIGNED, size: 16, address: { read: "3164", write: "3164" } },
  { name: "criticalAlarm_TDsg_OV_THD_3", type: SIGNED, size: 16, address: { read: "3165", write: "3165" } },
  { name: "criticalAlarm_TDsg_OV_THD_4", type: SIGNED, size: 16, address: { read: "3166", write: "3166" } },
  // 充电欠温阈值                                        TChg_UV_THD[4]
  { name: "criticalAlarm_TChg_UV_THD_1", type: SIGNED, size: 16, address: { read: "3167", write: "3167" } },
  { name: "criticalAlarm_TChg_UV_THD_2", type: SIGNED, size: 16, address: { read: "3168", write: "3168" } },
  { name: "criticalAlarm_TChg_UV_THD_3", type: SIGNED, size: 16, address: { read: "3169", write: "3169" } },
  { name: "criticalAlarm_TChg_UV_THD_4", type: SIGNED, size: 16, address: { read: "316A", write: "316A" } },
  // 放电欠温阈值                                   TDsg_UV_THD[4]
  { name: "criticalAlarm_TDsg_UV_THD_1", type: SIGNED, size: 16, address: { read: "316B", write: "316B" } },
  { name: "criticalAlarm_TDsg_UV_THD_2", type: SIGNED, size: 16, address: { read: "316C", write: "316C" } },
  { name: "criticalAlarm_TDsg_UV_THD_3", type: SIGNED, size: 16, address: { read: "316D", write: "316D" } },
  { name: "criticalAlarm_TDsg_UV_THD_4", type: SIGNED, size: 16, address: { read: "316E", write: "316E" } },
  // 电池温差过大                                        Tdiff_cell_OV_THD[4]
  { name: "criticalAlarm_Tdiff_cell_OV_THD_1", type: SIGNED, size: 16, address: { read: "316F", write: "316F" } },
  { name: "criticalAlarm_Tdiff_cell_OV_THD_2", type: SIGNED, size: 16, address: { read: "3170", write: "3170" } },
  { name: "criticalAlarm_Tdiff_cell_OV_THD_3", type: SIGNED, size: 16, address: { read: "3171", write: "3171" } },
  { name: "criticalAlarm_Tdiff_cell_OV_THD_4", type: SIGNED, size: 16, address: { read: "3172", write: "3172" } },
  // 均衡过温保护门限                                Tbalance_OverPretTHD[4]
  { name: "criticalAlarm_Tbalance_OverPretTHD_1", type: SIGNED, size: 16, address: { read: "3173", write: "3173" } },
  { name: "criticalAlarm_Tbalance_OverPretTHD_2", type: SIGNED, size: 16, address: { read: "3174", write: "3174" } },
  { name: "criticalAlarm_Tbalance_OverPretTHD_3", type: SIGNED, size: 16, address: { read: "3175", write: "3175" } },
  { name: "criticalAlarm_Tbalance_OverPretTHD_4", type: SIGNED, size: 16, address: { read: "3176", write: "3176" } },
  // 绝缘对地阻抗过低保护门限                              high_resistance_UnderPretTHD[4]
  { name: "criticalAlarm_high_resistance_UnderPretTHD_1", type: UNSIGNED, size: 16, address: { read: "3177", write: "3177" } },
  { name: "criticalAlarm_high_resistance_UnderPretTHD_2", type: UNSIGNED, size: 16, address: { read: "3178", write: "3178" } },
  { name: "criticalAlarm_high_resistance_UnderPretTHD_3", type: UNSIGNED, size: 16, address: { read: "3179", write: "3179" } },
  { name: "criticalAlarm_high_resistance_UnderPretTHD_4", type: UNSIGNED, size: 16, address: { read: "317A", write: "317A" } },
  // 总压采样异常                                      Vtotal_DiffPretTHD[4]
  { name: "criticalAlarm_Vtotal_DiffPretTHD_1", type: UNSIGNED, size: 16, address: { read: "317B", write: "317B" } },
  { name: "criticalAlarm_Vtotal_DiffPretTHD_2", type: UNSIGNED, size: 16, address: { read: "317C", write: "317C" } },
  { name: "criticalAlarm_Vtotal_DiffPretTHD_3", type: UNSIGNED, size: 16, address: { read: "317D", write: "317D" } },
  { name: "criticalAlarm_Vtotal_DiffPretTHD_4", type: UNSIGNED, size: 16, address: { read: "317E", write: "317E" } },
  // 静置过温阈值                                            TSLIENT_OV_THD[4]
  { name: "criticalAlarm_TSLIENT_OV_THD_1", type: SIGNED, size: 16, address: { read: "317F", write: "317F" } },
  { name: "criticalAlarm_TSLIENT_OV_THD_2", type: SIGNED, size: 16, address: { read: "3180", write: "3180" } },
  { name: "criticalAlarm_TSLIENT_OV_THD_3", type: SIGNED, size: 16, address: { read: "3181", write: "3181" } },
  { name: "criticalAlarm_TSLIENT_OV_THD_4", type: SIGNED, size: 16, address: { read: "3182", write: "3182" } },
  // 静置欠温阈值                                             TSLIENT_UV_THD[4]
  { name: "criticalAlarm_TSLIENT_UV_THD_1", type: SIGNED, size: 16, address: { read: "3183", write: "3183" } },
  { name: "criticalAlarm_TSLIENT_UV_THD_2", type: SIGNED, size: 16, address: { read: "3184", write: "3184" } },
  { name: "criticalAlarm_TSLIENT_UV_THD_3", type: SIGNED, size: 16, address: { read: "3185", write: "3185" } },
  { name: "criticalAlarm_TSLIENT_UV_THD_4", type: SIGNED, size: 16, address: { read: "3186", write: "3186" } },
  { name: "pb_voltage", type: UNSIGNED, size: 16, address: { read: "4004", write: "4004" } },
  { name: "pb_current", type: UNSIGNED, size: 16, address: { read: "4005", write: "4005" } },
  { name: "pb_socsoh", type: UNSIGNED, size: 16, address: { read: "4006", write: "4006" } },
  { name: "pb_systemInfo", type: UNSIGNED, size: 16, address: { read: "4007", write: "4007" } },
  { name: "pb_singleMaxVoltage", type: UNSIGNED, size: 16, address: { read: "4008", write: "4008" } },
  { name: "pb_singleMaxVoltageNo", type: UNSIGNED, size: 16, address: { read: "4009", write: "4009" } },
  { name: "pb_singleMinVoltage", type: UNSIGNED, size: 16, address: { read: "400A", write: "400A" } },
  { name: "pb_singleMinVoltageNo", type: UNSIGNED, size: 16, address: { read: "400B", write: "400B" } },
  { name: "pb_error1", type: UNSIGNED, size: 16, address: { read: "400C", write: "400C" } },
  { name: "pb_error2", type: UNSIGNED, size: 16, address: { read: "400D", write: "400D" } },
  { name: "pb_error3", type: UNSIGNED, size: 16, address: { read: "400E", write: "400E" } },
  { name: "pb_error4", type: UNSIGNED, size: 16, address: { read: "400F", write: "400F" } },
  { name: "pb_singleVol1", type: UNSIGNED, size: 16, address: { read: "4010", write: "4010" } },
  { name: "pb_singleVol2", type: UNSIGNED, size: 16, address: { read: "4011", write: "4011" } },
  { name: "pb_singleVol3", type: UNSIGNED, size: 16, address: { read: "4012", write: "4012" } },
  { name: "pb_singleVol4", type: UNSIGNED, size: 16, address: { read: "4013", write: "4013" } },
  { name: "pb_singleVol5", type: UNSIGNED, size: 16, address: { read: "4014", write: "4014" } },
  { name: "pb_singleVol6", type: UNSIGNED, size: 16, address: { read: "4015", write: "4015" } },
  { name: "pb_singleVol7", type: UNSIGNED, size: 16, address: { read: "4016", write: "4016" } },
  { name: "pb_singleVol8", type: UNSIGNED, size: 16, address: { read: "4017", write: "4017" } },
  { name: "pb_singleVol9", type: UNSIGNED, size: 16, address: { read: "4018", write: "4018" } },
  { name: "pb_singleVol10", type: UNSIGNED, size: 16, address: { read: "4019", write: "4019" } },
  { name: "pb_singleVol11", type: UNSIGNED, size: 16, address: { read: "401A", write: "401A" } },
  { name: "pb_singleVol12", type: UNSIGNED, size: 16, address: { read: "401B", write: "401B" } },
  { name: "pb_singleVol13", type: UNSIGNED, size: 16, address: { read: "401C", write: "401C" } },
  { name: "pb_singleVol14", type: UNSIGNED, size: 16, address: { read: "401D", write: "401D" } },
  { name: "pb_singleVol15", type: UNSIGNED, size: 16, address: { read: "401E", write: "401E" } },
  { name: "pb_singleVol16", type: UNSIGNED, size: 16, address: { read: "401F", write: "401F" } },
  { name: "pb_mosTemp1", type: UNSIGNED, size: 16, address: { read: "4020", write: "4020" } },
  { name: "pb_lcTemp2", type: UNSIGNED, size: 16, address: { read: "4021", write: "4021" } },
  { name: "pb_equaliTemp1", type: UNSIGNED, size: 16, address: { read: "4022", write: "4022" } },
  { name: "pb_equaliTemp2", type: UNSIGNED, size: 16, address: { read: "4023", write: "4023" } },
  { name: "pb_singleTemp1", type: UNSIGNED, size: 16, address: { read: "4024", write: "4024" } },
  { name: "pb_singleTemp2", type: UNSIGNED, size: 16, address: { read: "4025", write: "4025" } },
  { name: "pb_singleTemp3", type: UNSIGNED, size: 16, address: { read: "4026", write: "4026" } },
  { name: "pb_singleTemp4", type: UNSIGNED, size: 16, address: { read: "4027", write: "4027" } },
  { name: "pb_singleTemp5", type: UNSIGNED, size: 16, address: { read: "4028", write: "4028" } },
  { name: "pb_singleTemp6", type: UNSIGNED, size: 16, address: { read: "4029", write: "4029" } },
  { name: "pb_singleTemp7", type: UNSIGNED, size: 16, address: { read: "402A", write: "402A" } },
  { name: "pb_singleTemp8", type: UNSIGNED, size: 16, address: { read: "402B", write: "402B" } },
  { name: "pb_singleTemp9", type: UNSIGNED, size: 16, address: { read: "402C", write: "402C" } },
  { name: "pb_singleTemp10", type: UNSIGNED, size: 16, address: { read: "402D", write: "402D" } },
  { name: "pb_singleTemp11", type: UNSIGNED, size: 16, address: { read: "402E", write: "402E" } },
  { name: "pb_singleTemp12", type: UNSIGNED, size: 16, address: { read: "402F", write: "402F" } },
  { name: "pb_singleTemp13",  type: UNSIGNED, size: 16, address: { read: "4030", write: "4030" } },
  { name: "pb_singleTemp14",  type: UNSIGNED, size: 16, address: { read: "4031", write: "4031" } },
  { name: "pb_singleTemp15", type: UNSIGNED, size: 16, address: { read: "4032", write: "4032" }  },
  { name: "pb_singleTemp16", type: UNSIGNED, size: 16, address: { read: "4033", write: "4033" } },
  { name: "rackOnlineStatus", type: UNSIGNED, size: 16, address: { read : "1005", write: ""}},
  { name: "systemCharge", type: UNSIGNED, size: 16, address: { read : "1007", write: ""}},
  { name: "rackWarningSummary", type: UNSIGNED, size: 16, address: { read : "1009", write: ""}},
  { name: "rackAlarmSummary", type: UNSIGNED, size: 16, address: { read : "100A", write: ""}},
  { name: "rackCriticalAlarmSummary", type: UNSIGNED, size: 16, address: { read : "100B", write: ""}},
  { name: "systemTotalVoltage", type: UNSIGNED, size: 16, address: { read : "100C", write: ""}},
  { name: "systemTotalCurrent", type: SIGNED, size: 16, address: { read : "100D", write: ""}},
  { name: "systemSoc", type: UNSIGNED, size: 16, address: { read : "100E", write: ""}},
  { name: "systemSoh", type: UNSIGNED, size: 16, address: { read : "100F", write: ""}},
  { name: "systemInsulation", type: UNSIGNED, size: 16, address: { read : "1010", write: ""}},
  { name: "systemEnableChargeEnergy", type: UNSIGNED, size: 16, address: { read : "1011", write: ""}},
  { name: "systemEnableMaxChargeCurrent", type: UNSIGNED, size: 16, address: { read : "1013", write: ""}},
  { name: "systemEnableMaxDischargeCurrent", type: UNSIGNED, size: 16, address: { read : "1014", write: ""}},
  { name: "systemMaxVolCellRackId", type: UNSIGNED, size: 16, address: { read : "1017", write: ""}},
  { name: "systemMaxVolCellSlaveId", type: UNSIGNED, size: 16, address: { read : "1018", write: ""}},
  { name: "systemMaxVolCellId", type: UNSIGNED, size: 16, address: { read : "1019", write: ""}},
  { name: "systemMaxCellVoltage", type: UNSIGNED, size: 16, address: { read : "101A", write: ""}},
  { name: "systemMinVolCellRackId", type: UNSIGNED, size: 16, address: { read : "101B", write: ""}},
  { name: "systemMinVolCellSlaveId", type: UNSIGNED, size: 16, address: { read : "101C", write: ""}},
  { name: "systemMinVolCellId", type: UNSIGNED, size: 16, address: { read : "101D", write: ""}},
  { name: "systemMinCellVoltage", type: UNSIGNED, size: 16, address: { read : "101E", write: ""}},
  { name: "systemMaxTempCellRackId", type: UNSIGNED, size: 16, address: { read : "1020", write: ""}},
  { name: "systemMaxTempCellSlaveId", type: UNSIGNED, size: 16, address: { read : "1021", write: ""}},
  { name: "systemMaxTempCellId", type: UNSIGNED, size: 16, address: { read : "1022", write: ""}},
  { name: "systemMaxCellTemperature", type: SIGNED, size: 16, address: { read : "1023", write: ""}},
  { name: "systemMinTempCellRackId", type: UNSIGNED, size: 16, address: { read : "1024", write: ""}},
  { name: "systemMinTempCellSlaveId", type: UNSIGNED, size: 16, address: { read : "1025", write: ""}},
  { name: "systemMinTempCellId", type: UNSIGNED, size: 16, address: { read : "1026", write: ""}},
  { name: "systemMinCellTemperature", type: SIGNED, size: 16, address: { read : "1027", write: ""}},
  // { name: "accumulationHargeMSB", type: UNSIGNED, size: 16, address: { read : "1030", write: ""}},
  // { name: "accumulationHargeLSB", type: UNSIGNED, size: 16, address: { read : "1031", write: ""}},
  // { name: "accumulationDischargeEnergyMSB", type: UNSIGNED, size: 16, address: { read : "1032", write: ""}},
  // { name: "accumulationDischargeEnergyLSB", type: UNSIGNED, size: 16, address: { read : "1033", write: ""}},
  { name: "numberOfRacksInService", type: UNSIGNED, size: 16, address: { read : "1034", write: ""}},
  { name: "numberOfTotalRacks", type: UNSIGNED, size: 16, address: { read : "1035", write: ""}},
  { name: "maxDischargePower", type: UNSIGNED, size: 16, address: { read : "1037", write: ""}},
  { name: "maxChargePower", type: UNSIGNED, size: 16, address: { read : "1038", write: ""}},
  { name: "bmsHeartBeat", type: UNSIGNED, size: 16, address: { read : "1039", write: ""}},
  { name: "fireProtectionStatus", type: UNSIGNED, size: 16, address: { read : "103b", write: ""}},
  { name: "waterloggingStatus", type: UNSIGNED, size: 16, address: { read : "103c", write: ""}},
  { name: "airConditioningSum", type: UNSIGNED, size: 16, address: { read : "103d", write: ""}},
  { name: "airconditionStatus1", type: UNSIGNED, size: 16, address: { read : "103e", write: ""}},
  { name: "airconditionStatus2", type: UNSIGNED, size: 16, address: { read : "103f", write: ""}},
  { name: "dailyChargingEnergy", type: UNSIGNED, size: 16, address: { read : "1060", write: ""}},
  { name: "dailyDischargingEnergy", type: UNSIGNED, size: 16, address: { read : "1061", write: ""}},
  { name: "dailyEnergySaving", type: UNSIGNED, size: 16, address: { read : "1062", write: ""}},
  { name: "totalEnergySavingMSB", type: UNSIGNED, size: 16, address: { read : "1063", write: ""}},
  { name: "totalEnergySavingLSB", type: UNSIGNED, size: 16, address: { read : "1064", write: ""}},
  { name: "alternatingCurrentActivePower", type: SIGNED, size: 16, address: { read : "1065", write: ""}},
  { name: "alternatingCurrentReactivePower", type: SIGNED, size: 16, address: { read : "1066", write: ""}},
  { name: "chargingPowerOne", type: UNSIGNED, size: 16, address: { read : "1070", write: "1070"}},
  { name: "chargingPowerTwo", type: UNSIGNED, size: 16, address: { read : "1071", write: "1071"}},
  { name: "chargingPowerThree", type: UNSIGNED, size: 16, address: { read : "1072", write: "1072"}},
  { name: "chargingPowerFour", type: UNSIGNED, size: 16, address: { read : "1073", write: "1073"}},
  { name: "dischargingPowerOne", type: UNSIGNED, size: 16, address: { read : "1074", write: "1074"}},
  { name: "dischargingPowerTwo", type: UNSIGNED, size: 16, address: { read : "1075", write: "1075"}},
  { name: "dischargingPowerThree", type: UNSIGNED, size: 16, address: { read : "1076", write: "1076"}},
  { name: "dischargingPowerFour", type: UNSIGNED, size: 16, address: { read : "1077", write: "1077"}},
  { name: "chargingPowerOneStartTime", type: UNSIGNED, size: 16, address: { read : "1078", write: "1078"}},
  { name: "chargingPowerOneEndTime", type: UNSIGNED, size: 16, address: { read : "1079", write: "1079"}},
  { name: "chargingPowerTwoStartTime", type: UNSIGNED, size: 16, address: { read : "107A", write: "107A"}},
  { name: "chargingPowerTwoEndTime", type: UNSIGNED, size: 16, address: { read : "107B", write: "107B"}},
  { name: "chargingPowerThreeStartTime", type: UNSIGNED, size: 16, address: { read : "107C", write: "107C"}},
  { name: "chargingPowerThreeEndTime", type: UNSIGNED, size: 16, address: { read : "107D", write: "107D"}},
  { name: "chargingPowerFourStartTime", type: UNSIGNED, size: 16, address: { read : "107E", write: "107E"}},
  { name: "chargingPowerFourEndTime",type: UNSIGNED, size: 16, address: { read : "107F", write: "107F"}},
  { name: "dischargingPowerOneStartTime", type: UNSIGNED, size: 16, address: { read : "1080", write: "1080"}},
  { name: "dischargingPowerOneEndTime", type: UNSIGNED, size: 16, address: { read : "1081", write: "1081"}},
  { name: "dischargingPowerTwoStartTime", type: UNSIGNED, size: 16, address: { read : "1082", write: "1082"}},
  { name: "dischargingPowerTwoEndTime",type: UNSIGNED, size: 16, address: { read : "1083", write: "1083"}},
  { name: "dischargingPowerThreeStartTime", type: UNSIGNED, size: 16, address: { read : "1084", write: "1084"}},
  { name: "dischargingPowerThreeEndTime", type: UNSIGNED, size: 16, address: { read : "1085", write: "1085"}},
  { name: "dischargingPowerFourStartTime", type: UNSIGNED, size: 16, address: { read : "1086", write: "1086"}},
  { name: "dischargingPowerFourEndTime", type: UNSIGNED, size: 16, address: { read : "1087", write: "1087"}},
  // 电价
  { name: "topElectricityPriceMSB", type: UNSIGNED, size: 16, address: { read: "1090", write: "1090" }  },
  { name: "topElectricityPriceLSB", type: UNSIGNED, size: 16, address: { read: "1091", write: "1091" }  },
  { name: "peakElectricityPriceMSB", type: UNSIGNED, size: 16, address: { read: "1092", write: "1092" }  },
  { name: "peakElectricityPriceLSB", type: UNSIGNED, size: 16, address: { read: "1093", write: "1093" }  },
  { name: "normalElectricityPriceMSB", type: UNSIGNED, size: 16, address: { read: "1094", write: "1094" }  },
  { name: "normalElectricityPriceLSB", type: UNSIGNED, size: 16, address: { read: "1095", write: "1095" }  },
  { name: "valleyElectricityPriceMSB", type: UNSIGNED, size: 16, address: { read: "1096", write: "1096" }  },
  { name: "valleyElectricityPriceLSB", type: UNSIGNED, size: 16, address: { read: "1097", write: "1097" }  },
  { name: "pointedStartOne", type: UNSIGNED, size: 16, address: { read: "1098", write: "1098" }  },
  { name: "pointedEndOne", type: UNSIGNED, size: 16, address: { read: "1099", write: "1099" }  },
  { name: "pointedStartTwo", type: UNSIGNED, size: 16, address: { read: "109A", write: "109A" }  },
  { name: "pointedEndTwo", type: UNSIGNED, size: 16, address: { read: "109B", write: "109B" }  },
  { name: "pointedStartThree", type: UNSIGNED, size: 16, address: { read: "109C", write: "109C" }  },
  { name: "pointedEndThree", type: UNSIGNED, size: 16, address: { read: "109D", write: "109D" }  },
  { name: "pointedStartFour", type: UNSIGNED, size: 16, address: { read: "109E", write: "109E" }  },
  { name: "pointedEndFour", type: UNSIGNED, size: 16, address: { read: "109F", write: "109F" }  },
  { name: "peakStartOne", type: UNSIGNED, size: 16, address: { read: "10A0", write: "10A0" }  },
  { name: "peakEndOne", type: UNSIGNED, size: 16, address: { read: "10A1", write: "10A1" }  },
  { name: "peakStartTwo", type: UNSIGNED, size: 16, address: { read: "10A2", write: "10A2" }  },
  { name: "peakEndTwo", type: UNSIGNED, size: 16, address: { read: "10A3", write: "10A3" }  },
  { name: "peakStartThree", type: UNSIGNED, size: 16, address: { read: "10A4", write: "10A4" }  },
  { name: "peakEndThree", type: UNSIGNED, size: 16, address: { read: "10A5", write: "10A5" }  },
  { name: "peakStartFour", type: UNSIGNED, size: 16, address: { read: "10A6", write: "10A6" }  },
  { name: "peakEndFour", type: UNSIGNED, size: 16, address: { read: "10A7", write: "10A7" }  },
  { name: "flatStartOne", type: UNSIGNED, size: 16, address: { read: "10A8", write: "10A8" }  },
  { name: "flatEndOne", type: UNSIGNED, size: 16, address: { read: "10A9", write: "10A9" }  },
  { name: "flatStartTwo", type: UNSIGNED, size: 16, address: { read: "10AA", write: "10AA" }  },
  { name: "flatEndTwo", type: UNSIGNED, size: 16, address: { read: "10AB", write: "10AB" }  },
  { name: "flatStartThree", type: UNSIGNED, size: 16, address: { read: "10AC", write: "10AC" }  },
  { name: "flatEndThree", type: UNSIGNED, size: 16, address: { read: "10AD", write: "10AD" }  },
  { name: "flatStartFour", type: UNSIGNED, size: 16, address: { read: "10AE", write: "10AE" }  },
  { name: "flatEndFour", type: UNSIGNED, size: 16, address: { read: "10AF", write: "10AF" }  },
  { name: "valleyStartOne", type: UNSIGNED, size: 16, address: { read: "10B0", write: "10B0" }  },
  { name: "valleyEndOne", type: UNSIGNED, size: 16, address: { read: "10B1", write: "10B1" }  },
  { name: "valleyStartTwo", type: UNSIGNED, size: 16, address: { read: "10B2", write: "10B2" }  },
  { name: "valleyEndTwo", type: UNSIGNED, size: 16, address: { read: "10B3", write: "10B3" }  },
  { name: "valleyStartThree", type: UNSIGNED, size: 16, address: { read: "10B4", write: "10B4" }  },
  { name: "valleyEndThree", type: UNSIGNED, size: 16, address: { read: "10B5", write: "10B5" }  },
  { name: "valleyStartFour", type: UNSIGNED, size: 16, address: { read: "10B6", write: "10B6" }  },
  { name: "valleyEndFour", type: UNSIGNED, size: 16, address: { read: "10B7", write: "10B7" }  },
  { name: "deviceTimeYear", type: UNSIGNED, size: 16, address: { read: "0003", write: "0003" }  },
  { name: "deviceTimeMonth", type: UNSIGNED, size: 16, address: { read: "0004", write: "0004" }  },
  { name: "deviceTimeDate", type: UNSIGNED, size: 16, address: { read: "0005", write: "0005" }  },
  { name: "deviceTimeHour", type: UNSIGNED, size: 16, address: { read: "0006", write: "0006" }  },
  { name: "deviceTimeMinute", type: UNSIGNED, size: 16, address: { read: "0007", write: "0007" }  },
  { name: "deviceTimeSecond", type: UNSIGNED, size: 16, address: { read: "0008", write: "0008" }  },
  { name: "modbusAddr", type: UNSIGNED, size: 16, address: { read: "0500", write: "0500" } },
  { name: "versionHigh", type: UNSIGNED, size: 16, address: { read: "0501", write: "" } },
  { name: "versionLow", type: UNSIGNED, size: 16, address: { read: "0502", write: "" } },
  { name: "temperature", type: SIGNED, size: 16, address: { read: "1040", write: "" } },
  { name: "humidity", type: UNSIGNED, size: 16, address: { read: "1041", write: "" } },
  { name: "rackFaultSummary", type: UNSIGNED, size: 16, address: { read: "103A", write: "" } },
  { name: "systemState", type: UNSIGNED, size: 16, address: { read: "160E", write: "1626" } },
  { name: "onOffState", type: UNSIGNED, size: 16, address: { read: "160f", write: "" } },
  { name: "dailyTopChargingDnergy", type: UNSIGNED, size: 16, address: { read: "16C0", write: "" } },
  { name: "dailyTopDischargingDnergy", type: UNSIGNED, size: 16, address: { read: "16C1", write: "" } },
  { name: "dailyPeakChargingDnergy", type: UNSIGNED, size: 16, address: { read: "16C2", write: "" } },
  { name: "dailyPeakDischargingDnergy", type: UNSIGNED, size: 16, address: { read: "16C3", write: "" } },
  { name: "dailyNormalChargingDnergy", type: UNSIGNED, size: 16, address: { read: "16C4", write: "" } },
  { name: "dailyNormalDischargingDnergy", type: UNSIGNED, size: 16, address: { read: "16C5", write: "" } },
  { name: "dailyValleyChargingDnergy", type: UNSIGNED, size: 16, address: { read: "16C6", write: "" } },
  { name: "dailyValleyDischargingDnergy", type: UNSIGNED, size: 16, address: { read: "16C7", write: "" } },
  { name: "totalTopChargingDnergyMSB", type: UNSIGNED, size: 16, address: { read: "16C8", write: "" } },
  { name: "totalTopChargingDnergyLSB", type: UNSIGNED, size: 16, address: { read: "16C9", write: "" } },
  { name: "totalTopDischargingDnergyMSB", type: UNSIGNED, size: 16, address: { read: "16CA", write: "" } },
  { name: "totalTopDischargingDnergyLSB", type: UNSIGNED, size: 16, address: { read: "16CB", write: "" } },
  { name: "totalPeakChargingDnergyMSB", type: UNSIGNED, size: 16, address: { read: "16CC", write: "" } },
  { name: "totalPeakChargingDnergyLSB", type: UNSIGNED, size: 16, address: { read: "16CD", write: "" } },
  { name: "totalPeakDischargingDnergyMSB", type: UNSIGNED, size: 16, address: { read: "16CE", write: "" } },
  { name: "totalPeakDischargingDnergyLSB", type: UNSIGNED, size: 16, address: { read: "16CF", write: "" } },
  { name: "totalNormalChargingDnergyMSB", type: UNSIGNED, size: 16, address: { read: "16D0", write: "" } },
  { name: "totalNormalChargingDnergyLSB", type: UNSIGNED, size: 16, address: { read: "16D1", write: "" } },
  { name: "totalNormalDischargingDnergyMSB", type: UNSIGNED, size: 16, address: { read: "16D2", write: "" } },
  { name: "totalNormalDischargingDnergyLSB", type: UNSIGNED, size: 16, address: { read: "16D3", write: "" } },
  { name: "totalValleyChargingDnergyMSB", type: UNSIGNED, size: 16, address: { read: "16D4", write: "" } },
  { name: "totalValleyChargingDnergyLSB", type: UNSIGNED, size: 16, address: { read: "16D5", write: "" } },
  { name: "totalValleyDischargingDnergyMSB", type: UNSIGNED, size: 16, address: { read: "16D6", write: "" } },
  { name: "totalValleyDischargingDnergyLSB", type: UNSIGNED, size: 16, address: { read: "16D7", write: "" } },
  // 下面这些是新的 modbus 用的地址
  // 空调状态
  { name: "coolingSetPoint", type: UNSIGNED, size: 16, address: { read: "7000", write: "" } },
  { name: "coolingDeviation", type: UNSIGNED, size: 16, address: { read: "7001", write: "" } },
  { name: "highTemperationAlarmValue", type: UNSIGNED, size: 16, address: { read: "7002", write: "" } },
  { name: "lowTemperationAlarmValue", type: SIGNED, size: 16, address: { read: "7003", write: "" } },
  { name: "heatingSetPoint", type: UNSIGNED, size: 16, address: { read: "7004", write: "" } },
  { name: "heatingDeviation", type: UNSIGNED, size: 16, address: { read: "7005", write: "" } },
  { name: "switchOnOff", type: UNSIGNED, size: 16, address: { read: "7006", write: "" } },
  { name: "startCoolingOrder", type: UNSIGNED, size: 16, address: { read: "7007", write: "" } },
  { name: "startFanOrder", type: UNSIGNED, size: 16, address: { read: "7008", write: "" } },
  { name: "startHoldonOrder", type: UNSIGNED, size: 16, address: { read: "7009", write: "" } },
  { name: "startHeatingOrder", type: UNSIGNED, size: 16, address: { read: "700A", write: "" } },
  { name: "currentTemperatureInCabinet", type: UNSIGNED, size: 16, address: { read: "700B", write: "" } },
  { name: "currentHumidityInCabinet", type: UNSIGNED, size: 16, address: { read: "700C", write: "" } },
  { name: "sensorFault", type: UNSIGNED, size: 16, address: { read: "700D", write: "" } },
  { name: "highLowVoltageAlarm", type: UNSIGNED, size: 16, address: { read: "700E", write: "" } },
  { name: "highLowTemperatureAlarm", type: UNSIGNED, size: 16, address: { read: "700F", write: "" } },
  { name: "highLowPressureAlarm", type: UNSIGNED, size: 16, address: { read: "7010", write: "" } },
  { name: "dehumidificationStartTemperature", type: UNSIGNED, size: 16, address: { read: "7011", write: "" } },
  { name: "dehumidificationStopTemperature", type: UNSIGNED, size: 16, address: { read: "7012", write: "" } },
  { name: "dehumidificationStartHumidity", type: UNSIGNED, size: 16, address: { read: "7013", write: "" } },
  { name: "dehumidificationStopHumidity", type: UNSIGNED, size: 16, address: { read: "7014", write: "" } },
  { name: "unitState", type: UNSIGNED, size: 16, address: { read: "7015", write: "" } },
  { name: "voltageValue", type: UNSIGNED, size: 16, address: { read: "7016", write: "" } },
  { name: "softwareName", type: UNSIGNED, size: 16, address: { read: "7017", write: "" } },
  { name: "softwareVersion", type: UNSIGNED, size: 16, address: { read: "7018", write: "" } },
  { name: "accessControlAlarm", type: UNSIGNED, size: 16, address: { read: "7019", write: "" } },
  { name: "heaterAlarm", type: UNSIGNED, size: 16, address: { read: "701A", write: "" } },
  { name: "coolingState", type: UNSIGNED, size: 16, address: { read: "701B", write: "" } },
  { name: "heatingState", type: UNSIGNED, size: 16, address: { read: "701C", write: "" } },
  { name: "internalFanState", type: UNSIGNED, size: 16, address: { read: "701D", write: "" } },
  { name: "externalFanState", type: UNSIGNED, size: 16, address: { read: "701E", write: "" } },
  { name: "internalFanrotateSpeed", type: UNSIGNED, size: 16, address: { read: "701F", write: "" } },
  { name: "externalFanrotateSpeed", type: UNSIGNED, size: 16, address: { read: "7020", write: "" } },
  { name: "internalFanAlarm", type: UNSIGNED, size: 16, address: { read: "7021", write: "" } },
  { name: "externalFanAlarm", type: UNSIGNED, size: 16, address: { read: "7022", write: "" } },
  { name: "deviceWorkState", type: UNSIGNED, size: 16, address: { read: "7023", write: "" } },
  { name: "compressorState", type: UNSIGNED, size: 16, address: { read: "7024", write: "" } },
  { name: "runningStateElectricHeater", type: UNSIGNED, size: 16, address: { read: "7025", write: "" } },
  { name: "defrostingTemperature", type: UNSIGNED, size: 16, address: { read: "7026", write: "" } },
  { name: "condensingTemperature", type: UNSIGNED, size: 16, address: { read: "7027", write: "" } },
  { name: "cabinetTemperature", type: UNSIGNED, size: 16, address: { read: "7028", write: "" } },
  { name: "cabinetHumidity", type: UNSIGNED, size: 16, address: { read: "7029", write: "" } },
  { name: "outletAirTemperature", type: UNSIGNED, size: 16, address: { read: "702A", write: "" } },
  { name: "hostTemperature", type: UNSIGNED, size: 16, address: { read: "702B", write: "" } },
  { name: "hostHumidity", type: UNSIGNED, size: 16, address: { read: "702C", write: "" } },
  { name: "coolingSetTemperature", type: UNSIGNED, size: 16, address: { read: "702D", write: "" } },
  { name: "coolingControlBackDifference", type: UNSIGNED, size: 16, address: { read: "702E", write: "" } },
  { name: "heatingSetTemperature", type: SIGNED, size: 16, address: { read: "702F", write: "" } },
  { name: "heatingControlBackDifference", type: UNSIGNED, size: 16, address: { read: "7030", write: "" } },
  { name: "humiditySetValue", type: UNSIGNED, size: 16, address: { read: "7031", write: "" } },
  { name: "humidityControlBackDifference", type: UNSIGNED, size: 16, address: { read: "7032", write: "" } },
  { name: "cabinetOverTemperaturePoint", type: UNSIGNED, size: 16, address: { read: "7033", write: "" } },
  { name: "cabinetUnderTemperaturePoint", type: SIGNED, size: 16, address: { read: "7034", write: "" } },
  { name: "cabinetOverHumidityPoint", type: UNSIGNED, size: 16, address: { read: "7035", write: "" } },
  { name: "monitorSwitch", type: UNSIGNED, size: 16, address: { read: "7036", write: "" } },
  { name: "forceMode", type: UNSIGNED, size: 16, address: { read: "7037", write: "" } },
  { name: "cabinetOverTemperatureAlarm", type: UNSIGNED, size: 16, address: { read: "7038", write: "" } },
  { name: "cabinetUnderTemperatureAlarm", type: UNSIGNED, size: 16, address: { read: "7039", write: "" } },
  { name: "humidityOverAlarm", type: UNSIGNED, size: 16, address: { read: "703A", write: "" } },
  { name: "humidityUnderAlarm", type: UNSIGNED, size: 16, address: { read: "703B", write: "" } },
  { name: "coilAntiFreezeAlarm", type: UNSIGNED, size: 16, address: { read: "703C", write: "" } },
  { name: "deforstProbeFaultAlarm", type: UNSIGNED, size: 16, address: { read: "703D", write: "" } },
  { name: "condensateTemperatureProbeFaultAlarm", type: UNSIGNED, size: 16, address: { read: "703E", write: "" } },
  { name: "cabinetTemperatureProbeFaultAlarm", type: UNSIGNED, size: 16, address: { read: "703F", write: "" } },
  { name: "outletAirTemperatureProbeFaultAlarm", type: UNSIGNED, size: 16, address: { read: "7040", write: "" } },
  { name: "temperatureProbeFaultAlarm", type: UNSIGNED, size: 16, address: { read: "7041", write: "" } },
  { name: "internalFanFaultAlarm", type: UNSIGNED, size: 16, address: { read: "7042", write: "" } },
  { name: "compressorFaultAlarm", type: UNSIGNED, size: 16, address: { read: "7043", write: "" } },
  { name: "highVoltageAlarm", type: UNSIGNED, size: 16, address: { read: "7044", write: "" } },
  { name: "lowVoltageAlarm", type: UNSIGNED, size: 16, address: { read: "7045", write: "" } },
  { name: "phaseSequenceAlarm", type: UNSIGNED, size: 16, address: { read: "7046", write: "" } },
  { name: "contactAddress", type: UNSIGNED, size: 16, address: { read: "7047", write: "" } },
  { name: "baudrate", type: UNSIGNED, size: 16, address: { read: "7048", write: "" } },
  // 消防参数
  { name: "firstLevelConcentration",type: UNSIGNED, size: 16,address: { read: "38C8", write: "38C8" } },
  { name: "secondaryConcentration",type: UNSIGNED, size: 16,address: { read: "38C9", write: "38C9" } },
  { name: "thirdLevelConcentration",type: UNSIGNED, size: 16,address: { read: "38CA", write: "38CA" } },
  { name: "hfirstLevelConcentration",type: UNSIGNED, size: 16,address: { read: "38CB", write: "38CB" } },
  { name: "hsecondaryConcentration",type: UNSIGNED, size: 16,address: { read: "38CC", write: "38CC" } },
  { name: "hthirdLevelConcentration",type: UNSIGNED, size: 16,address: { read: "38CD", write: "38CD" } },
  { name: "vfirstLevelConcentration",type: UNSIGNED, size: 16,address: { read: "38CE", write: "38CE" } },
  { name: "vsecondaryConcentration",type: UNSIGNED, size: 16,address: { read: "38CF", write: "38CF" } },
  { name: "vthirdLevelConcentration",type: UNSIGNED, size: 16,address: { read: "38D0", write: "38D0" } },
  { name: "firstStageOverheating",type: UNSIGNED, size: 16,address: { read: "38D1", write: "38D1" } },
  { name: "secondaryOverheating",type: UNSIGNED, size: 16,address: { read: "38D2", write: "38D2" } },
  { name: "closedState",type: UNSIGNED, size: 16,address: { read: "38D3", write: "38D3" } },
  { name: "adjustmentAmount",type: UNSIGNED, size: 16,address: { read: "38D4", write: "38D4" } },
  { name: "heatBattery",type: SIGNED, size: 16,address: { read: "38D5", write: "38D5" } },
  { name: "opening",type: UNSIGNED, size: 16,address: { read: "38D6", write: "38D6" } },
  // 消防状态
  { name: "cylinderBankPressure",type: UNSIGNED, size: 16,address: { read: "5000", write: "" } },
  { name: "primaryAlarm",type: UNSIGNED, size: 16,address: { read: "5001", write: "" } },
  { name: "LevelTwoAlarm",type: UNSIGNED, size: 16,address: { read: "5002", write: "" } },
  { name: "co2Concentration",type: UNSIGNED, size: 16,address: { read: "5003", write: "" } },
  { name: "pressure",type: UNSIGNED, size: 16,address: { read: "5004", write: "" } },
  { name: "temperature",type: UNSIGNED, size: 16,address: { read: "5005", write: "" } },
  { name: "pm2_5Concentration",type: UNSIGNED, size: 16,address: { read: "5006", write: "" } },
  { name: "coConcentration",type: UNSIGNED, size: 16,address: { read: "5007", write: "" } },
  { name: "startSignal",type: UNSIGNED, size: 16,address: { read: "5008", write: "" } },
  { name: "weakCurrentCutting",type: UNSIGNED, size: 16,address: { read: "5009", write: "" } },
  { name: "lowVoltageAlarm",type: UNSIGNED, size: 16,address: { read: "500A", write: "" } },
  { name: "highVoltageCutting",type: UNSIGNED, size: 16,address: { read: "500B", write: "" } },
  { name: "soundLightAlarm",type: UNSIGNED, size: 16,address: { read: "500C", write: "" } },
  { name: "explosionProofFanRespondsOutput",type: UNSIGNED, size: 16,address: { read: "500D", write: "" } },
  { name: "smokeSense",type: UNSIGNED, size: 16,address: { read: "500E", write: "" } },
  { name: "temperatureSense",type: UNSIGNED, size: 16,address: { read: "500F", write: "" } },
  { name: "bmsInput",type: UNSIGNED, size: 16,address: { read: "5010", write: "" } },
  { name: "waterSensor",type: UNSIGNED, size: 16,address: { read: "5011", write: "" } },
  { name: "sighalFeedbackDevice",type: UNSIGNED, size: 16,address: { read: "5012", write: "" } },
  { name: "manualStart",type: UNSIGNED, size: 16,address: { read: "5013", write: "" } },
  { name: "manualReset",type: UNSIGNED, size: 16,address: { read: "5014", write: "" } },
  { name: "faultAlarm",type: UNSIGNED, size: 16,address: { read: "5015", write: "" } },
  { name: "contactAddress",type: UNSIGNED, size: 16,address: { read: "5016", write: "" } },
  { name: "baudRate",type: UNSIGNED, size: 16,address: { read: "5017", write: "" } },
  // 水浸状态
  { name: "registerNumber2Status",type: UNSIGNED, size: 16,address: { read: "6000", write: "" } },
  { name: "alarmDelay",type: UNSIGNED, size: 16,address: { read: "6001", write: "" } },
  { name: "currentSensitivity",type: UNSIGNED, size: 16,address: { read: "6002", write: "" } },
  { name: "contactAddress",type: UNSIGNED, size: 16,address: { read: "6003", write: "" } },
  { name: "baudRate",type: UNSIGNED, size: 16,address: { read: "6004", write: "" } },
  // PCS
  { name: "acVoltagePhaseA",type: UNSIGNED, size: 16,address: { read: "1582", write: "" } },
  { name: "acVoltagePhaseB",type: UNSIGNED, size: 16,address: { read: "1583", write: "" } },
  { name: "acVoltagePhaseC",type: UNSIGNED, size: 16,address: { read: "1584", write: "" } },
  { name: "acCurrentPhaseA",type: UNSIGNED, size: 16,address: { read: "1585", write: "" } },
  { name: "acCurrentPhaseB",type: UNSIGNED, size: 16,address: { read: "1586", write: "" } },
  { name: "acCurrentPhaseC",type: UNSIGNED, size: 16,address: { read: "1587", write: "" } },
  { name: "acApparentPower",type: UNSIGNED, size: 16,address: { read: "1588", write: "" } },
  { name: "acFrequency",type: UNSIGNED, size: 16,address: { read: "1589", write: "" } },
  { name: "powerFactor",type: UNSIGNED, size: 16,address: { read: "158a", write: "" } },
  { name: "unitTemperature",type: UNSIGNED, size: 16,address: { read: "158b", write: "" } },
  { name: "batteryPackSOC",type: UNSIGNED, size: 16,address: { read: "158c", write: "" } },
  { name: "systemAlarmBit",type: UNSIGNED, size: 16,address: { read: "158d", write: "" } },
  { name: "dcVoltage1",type: UNSIGNED, size: 16,address: { read: "158e", write: "" } },
  { name: "dcCurrent1",type: UNSIGNED, size: 16,address: { read: "158f", write: "" } },
  { name: "dcPower1",type: UNSIGNED, size: 16,address: { read: "1590", write: "" } },
  { name: "dcVoltage2",type: UNSIGNED, size: 16,address: { read: "1591", write: "" } },
  { name: "dcCurrent2",type: UNSIGNED, size: 16,address: { read: "1592", write: "" } },
  { name: "dcPower2",type: UNSIGNED, size: 16,address: { read: "1593", write: "" } },
  { name: "dcVoltage3",type: UNSIGNED, size: 16,address: { read: "1594", write: "" } },
  { name: "dcCurrent3",type: UNSIGNED, size: 16,address: { read: "1595", write: "" } },
  { name: "dcPower3",type: UNSIGNED, size: 16,address: { read: "1596", write: "" } },
  { name: "dcVoltage4",type: UNSIGNED, size: 16,address: { read: "1597", write: "" } },
  { name: "dcCurrent4",type: UNSIGNED, size: 16,address: { read: "1598", write: "" } },
  { name: "dcPower4",type: UNSIGNED, size: 16,address: { read: "1599", write: "" } },
  { name: "dcVoltage5",type: UNSIGNED, size: 16,address: { read: "159a", write: "" } },
  { name: "dcCurrent5",type: UNSIGNED, size: 16,address: { read: "159b", write: "" } },
  { name: "dcPower5",type: UNSIGNED, size: 16,address: { read: "159c", write: "" } },
  { name: "dcVoltage6",type: UNSIGNED, size: 16,address: { read: "159d", write: "" } },
  { name: "dcCurrent6",type: UNSIGNED, size: 16,address: { read: "159e", write: "" } },
  { name: "dcPower6",type: UNSIGNED, size: 16,address: { read: "159f", write: "" } },
  { name: "dcVoltage7",type: UNSIGNED, size: 16,address: { read: "1600", write: "" } },
  { name: "dcCurrent7",type: UNSIGNED, size: 16,address: { read: "1601", write: "" } },
  { name: "dcPower7",type: UNSIGNED, size: 16,address: { read: "1602", write: "" } },
  { name: "dcVoltage8",type: UNSIGNED, size: 16,address: { read: "1603", write: "" } },
  { name: "dcCurrent8",type: UNSIGNED, size: 16,address: { read: "1604", write: "" } },
  { name: "dcPower8",type: UNSIGNED, size: 16,address: { read: "1605", write: "" } },
  { name: "dcVoltage9",type: UNSIGNED, size: 16,address: { read: "1606", write: "" } },
  { name: "dcCurrent9",type: UNSIGNED, size: 16,address: { read: "1607", write: "" } },
  { name: "dcPower9",type: UNSIGNED, size: 16,address: { read: "1608", write: "" } },
  { name: "dcVoltage10",type: UNSIGNED, size: 16,address: { read: "1609", write: "" } },
  { name: "dcCurrent10",type: UNSIGNED, size: 16,address: { read: "160a", write: "" } },
  { name: "dcPower10",type: UNSIGNED, size: 16,address: { read: "160b", write: "" } },
  { name: "softwareVersionNumberHighBit",type: UNSIGNED, size: 16,address: { read: "160c", write: "" } },
  { name: "softwareVersionNumberLowBit",type: UNSIGNED, size: 16,address: { read: "160d", write: "" } },
  { name: "systemStatus",type: UNSIGNED, size: 16,address: { read: "160e", write: "" } },
  { name: "statusSwitchOnOff",type: UNSIGNED, size: 16,address: { read: "160f", write: "" } },
  { name: "statusModule1",type: UNSIGNED, size: 16,address: { read: "1610", write: "" } },
  { name: "statusModule2",type: UNSIGNED, size: 16,address: { read: "1611", write: "" } },
  { name: "statusModule3",type: UNSIGNED, size: 16,address: { read: "1612", write: "" } },
  { name: "statusModule4",type: UNSIGNED, size: 16,address: { read: "1613", write: "" } },
  { name: "statusModule5",type: UNSIGNED, size: 16,address: { read: "1614", write: "" } },
  { name: "statusModule6",type: UNSIGNED, size: 16,address: { read: "1615", write: "" } },
  { name: "statusModule7",type: UNSIGNED, size: 16,address: { read: "1616", write: "" } },
  { name: "statusModule8",type: UNSIGNED, size: 16,address: { read: "1617", write: "" } },
  { name: "statusModule9",type: UNSIGNED, size: 16,address: { read: "1618", write: "" } },
  { name: "statusModule10",type: UNSIGNED, size: 16,address: { read: "1619", write: "" } },
  { name: "statusBMSModule1",type: UNSIGNED, size: 16,address: { read: "161a", write: "" } },
  { name: "statusBMSModule2",type: UNSIGNED, size: 16,address: { read: "161b", write: "" } },
  { name: "statusBMSModule3",type: UNSIGNED, size: 16,address: { read: "161c", write: "" } },
  { name: "statusBMSModule4",type: UNSIGNED, size: 16,address: { read: "161d", write: "" } },
  { name: "statusBMSModule5",type: UNSIGNED, size: 16,address: { read: "161e", write: "" } },
  { name: "statusBMSModule6",type: UNSIGNED, size: 16,address: { read: "161f", write: "" } },
  { name: "statusBMSModule7",type: UNSIGNED, size: 16,address: { read: "1620", write: "" } },
  { name: "statusBMSModule8",type: UNSIGNED, size: 16,address: { read: "1621", write: "" } },
  { name: "statusBMSModule9",type: UNSIGNED, size: 16,address: { read: "1622", write: "" } },
  { name: "statusBMSModule10",type: UNSIGNED, size: 16,address: { read: "1623", write: "" } },
  { name: "statusBMSModule11",type: UNSIGNED, size: 16,address: { read: "1624", write: "" } },
  { name: "statusBMSModule12",type: UNSIGNED, size: 16,address: { read: "1625", write: "" } },
  { name: "settingSwitchOnOff",type: UNSIGNED, size: 16,address: { read: "1626", write: "" } },
  { name: "settingActivePower",type: UNSIGNED, size: 16,address: { read: "1627", write: "" } },
  { name: "settingReactivePower",type: UNSIGNED, size: 16,address: { read: "1628", write: "" } },
  { name: "settingPowerFactor",type: UNSIGNED, size: 16,address: { read: "1629", write: "" } },
  { name: "settingTactics",type: UNSIGNED, size: 16,address: { read: "162a", write: "" } },
  { name: "upgradeExecution",type: UNSIGNED, size: 16,address: { read: "162b", write: "" } },
  { name: "acPortVoltage",type: UNSIGNED, size: 16,address: { read: "D000", write: "" } },
  { name: "acPortCurrent",type: SIGNED, size: 16,address: { read: "D001", write: "" } },
  { name: "acPortPower",type: SIGNED, size: 16,address: { read: "D002", write: "" } },
  { name: "moduleLgbt1",type: UNSIGNED, size: 16,address: { read: "D003", write: "" } },
  { name: "moduleLgbt2",type: UNSIGNED, size: 16,address: { read: "D004", write: "" } },
  { name: "moduleLgbt3",type: UNSIGNED, size: 16,address: { read: "D005", write: "" } },
  { name: "moduleLgbt4",type: UNSIGNED, size: 16,address: { read: "D006", write: "" } },
  { name: "moduleLgbt5",type: UNSIGNED, size: 16,address: { read: "D007", write: "" } },
  { name: "moduleLgbt6",type: UNSIGNED, size: 16,address: { read: "D008", write: "" } },
  { name: "temperatureModuleAirInlet",type: SIGNED, size: 16,address: { read: "D009", write: "" } },
  { name: "temperatureModuleAirOutlet",type: SIGNED, size: 16,address: { read: "D00A", write: "" } },
  { name: "acActivePowerPhaseA",type: SIGNED, size: 16,address: { read: "D00B", write: "" } },
  { name: "acActivePowerPhaseB",type: SIGNED, size: 16,address: { read: "D00C", write: "" } },
  { name: "acActivePowerPhaseC",type: SIGNED, size: 16,address: { read: "D00D", write: "" } },
  { name: "dcBusVoltage",type: UNSIGNED, size: 16,address: { read: "D00E", write: "" } },
  { name: "deviceRunningStatusBit",type: UNSIGNED, size: 16,address: { read: "D00F", write: "" } },
  { name: "systemSynthesisFaultBit",type: UNSIGNED, size: 16,address: { read: "D010", write: "" } },
  { name: "systemEnvironmentFaultBit",type: UNSIGNED, size: 16,address: { read: "D011", write: "" } },
  { name: "systemHardwareFault",type: UNSIGNED, size: 16,address: { read: "D012", write: "" } },
  { name: "systemTemperatureFaultBit",type: UNSIGNED, size: 16,address: { read: "D013", write: "" } },
  { name: "softwareVersionHighBit",type: UNSIGNED, size: 16,address: { read: "D014", write: "" } },
  { name: "softwareVersionLowBit",type: UNSIGNED, size: 16,address: { read: "D015", write: "" } },
  { name: "hardwareVersionHighBit",type: UNSIGNED, size: 16,address: { read: "D016", write: "" } },
  { name: "hardwareVersionLowBit",type: UNSIGNED, size: 16,address: { read: "D017", write: "" } },
  { name: "startShutDowmCommand",type: UNSIGNED, size: 16,address: { read: "D019", write: "" } },
  { name: "settingWorkMode",type: UNSIGNED, size: 16,address: { read: "D01A", write: "" } },
  { name: "settingDCCurrent",type: SIGNED, size: 16,address: { read: "D01B", write: "" } },
  { name: "settingDCPortVoltage",type: UNSIGNED, size: 16,address: { read: "D01C", write: "" } },
  { name: "settingDCPower",type: SIGNED, size: 16,address: { read: "D01D", write: "" } },
  { name: "activePowerPhaseA",type: SIGNED, size: 16,address: { read: "D01E", write: "" } },
  { name: "activePowerPhaseB",type: SIGNED, size: 16,address: { read: "D01F", write: "" } },
  { name: "activePowerPhaseC",type: SIGNED, size: 16,address: { read: "D020", write: "" } },
  { name: "gridCode",type: UNSIGNED, size: 16,address: { read: "D021", write: "" } },
  { name: "settingAcVoltage",type: UNSIGNED, size: 16,address: { read: "D022", write: "" } },
  { name: "settingAcCurrent",type: SIGNED, size: 16,address: { read: "D023", write: "" } },
  { name: "settingAcFrequency",type: UNSIGNED, size: 16,address: { read: "D024", write: "" } },
  { name: "activePowerChangeRate",type: UNSIGNED, size: 16,address: { read: "D025", write: "" } },
  { name: "reactivePowerSettingMode",type: UNSIGNED, size: 16,address: { read: "D026", write: "" } },
  { name: "reactivePowerChangeRate",type: UNSIGNED, size: 16,address: { read: "D027", write: "" } },
  { name: "threePhaseUnbalancedOutput",type: UNSIGNED, size: 16,address: { read: "D028", write: "" } },
  { name: "deviceType",type: UNSIGNED, size: 16,address: { read: "D029", write: "" } },
  { name: "settingCustomizationFunctionEnabled",type: UNSIGNED, size: 16,address: { read: "D02A", write: "" } },
  { name: "maximumDCVoltageLimit",type: UNSIGNED, size: 16,address: { read: "D02B", write: "" } },
  { name: "minimumDCVoltageLimit",type: UNSIGNED, size: 16,address: { read: "D02C", write: "" } },
  { name: "maximumBatteryChargingCurrent",type: UNSIGNED, size: 16,address: { read: "D02D", write: "" } },
  { name: "maximumBatteryDischargingCurrent",type: UNSIGNED, size: 16,address: { read: "D02E", write: "" } },
  { name: "rs485CommunicationAddress",type: UNSIGNED, size: 16,address: { read: "D02F", write: "" } },
  { name: "canCommunicationAddress",type: UNSIGNED, size: 16,address: { read: "D030", write: "" } },
  { name: "singleOrParallelFalg",type: UNSIGNED, size: 16,address: { read: "D031", write: "" } },
  { name: "numberParallelMachines",type: UNSIGNED, size: 16,address: { read: "D032", write: "" } },
  { name: "localParallelAddress",type: UNSIGNED, size: 16,address: { read: "D033", write: "" } },
  { name: "hostOrSlaveIdentity",type: UNSIGNED, size: 16,address: { read: "D034", write: "" } },
  { name: "gridConnectedOrOffGridDryContactEnabled",type: UNSIGNED, size: 16,address: { read: "D035", write: "" } },
  { name: "clearFaultStatus",type: UNSIGNED, size: 16,address: { read: "D036", write: "" } },
  { name: "factoryDataReset",type: UNSIGNED, size: 16,address: { read: "D037", write: "" } },
  { name: "enableHeartBeat",type: UNSIGNED, size: 16,address: { read: "D038", write: "" } },
  { name: "rs485CommunicationBaudRate",type: UNSIGNED, size: 16,address: { read: "D039", write: "" } },
  { name: "canCommunicationBaudRate",type: UNSIGNED, size: 16,address: { read: "D03A", write: "" } },
  { name: "enabledWith10TimesActivePowerChangeRate",type: UNSIGNED, size: 16,address: { read: "D03B", write: "" } },
  { name: "activePowerNonStandardSettingEnable",type: UNSIGNED, size: 16,address: { read: "D03C", write: "" } },
  { name: "nonStandardActiveSetting",type: SIGNED, size: 16,address: { read: "D03D", write: "" } },
  { name: "nonStandardAPhaseActivePower",type: SIGNED, size: 16,address: { read: "D03E", write: "" } },
  { name: "nonStandardBPhaseActivePower",type: SIGNED, size: 16,address: { read: "D03F", write: "" } },
  { name: "nonStandardCPhaseActivePower",type: SIGNED, size: 16,address: { read: "D040", write: "" } },
  { name: "acReactivePowerPhaseA",type: SIGNED, size: 16,address: { read: "D041", write: "" } },
  { name: "acReactivePowerPhaseB",type: SIGNED, size: 16,address: { read: "D042", write: "" } },
  { name: "acReactivePowerPhaseC",type: SIGNED, size: 16,address: { read: "D043", write: "" } },
  { name: "acReactivePowerSinglePhaseControlEnabled",type: UNSIGNED, size: 16,address: { read: "D044", write: "" } },
  { name: "modbusAddress",type: UNSIGNED, size: 16,address: { read: "D04F", write: "" } },
  { name: "modbusBaudRate",type: UNSIGNED, size: 16,address: { read: "D050", write: "" } },
  { name: "heartBeatEnable",type: UNSIGNED, size: 16,address: { read: "D051", write: "" } },
  { name: "emsCommunicationMode",type: UNSIGNED, size: 16,address: { read: "D052", write: "" } },
  { name: "modbusTCPEnable",type: UNSIGNED, size: 16,address: { read: "D053", write: "" } },
  { name: "modbusTCPWorkMode",type: UNSIGNED, size: 16,address: { read: "D054", write: "" } },
  { name: "tcpRemoteIPHighBit",type: UNSIGNED, size: 16,address: { read: "D055", write: "" } },
  { name: "tcpRemoteIPLowBit",type: UNSIGNED, size: 16,address: { read: "D056", write: "" } },
  { name: "tcpRemotePortNumber",type: UNSIGNED, size: 16,address: { read: "D057", write: "" } },
  { name: "tcpLocalIPHighBit",type: UNSIGNED, size: 16,address: { read: "D058", write: "" } },
  { name: "tcpLocalIPLowBit",type: UNSIGNED, size: 16,address: { read: "D059", write: "" } },
  { name: "tcpLocalPortNumber",type: UNSIGNED, size: 16,address: { read: "D05A", write: "" } },
  { name: "highSideVoltage",type: UNSIGNED, size: 16,address: { read: "D940", write: "" } },
  { name: "highSideCurrent",type: SIGNED, size: 16,address: { read: "D941", write: "" } },
  { name: "highSidePower",type: UNSIGNED, size: 16,address: { read: "D942", write: "" } },
  { name: "temperatureModuleIGBT1",type: SIGNED, size: 16,address: { read: "D943", write: "" } },
  { name: "temperatureModuleIGBT2",type: SIGNED, size: 16,address: { read: "D944", write: "" } },
  { name: "temperatureModuleIGBT3",type: SIGNED, size: 16,address: { read: "D945", write: "" } },
  { name: "temperatureModuleIGBT4",type: SIGNED, size: 16,address: { read: "D946", write: "" } },
  { name: "temperatureModuleIGBT5",type: SIGNED, size: 16,address: { read: "D947", write: "" } },
  { name: "temperatureModuleIGBT6",type: SIGNED, size: 16,address: { read: "D948", write: "" } },
  { name: "temperatureModuleAirInlet",type: SIGNED, size: 16,address: { read: "D949", write: "" } },
  { name: "temperatureModuleAirOutlet",type: SIGNED, size: 16,address: { read: "D94A", write: "" } },
  { name: "lowVoltageSideConnected1Voltage",type: UNSIGNED, size: 16,address: { read: "D94B", write: "" } },
  { name: "lowVoltageSideConnected1Current",type: SIGNED, size: 16,address: { read: "D94C", write: "" } },
  { name: "lowVoltageSideConnected1Power",type: SIGNED, size: 16,address: { read: "D94D", write: "" } },
  { name: "reserve",type: UNSIGNED, size: 16,address: { read: "D94E", write: "" } },
  { name: "lowVoltageSideConnected2Voltage",type: UNSIGNED, size: 16,address: { read: "D94F", write: "" } },
  { name: "lowVoltageSideConnected2Current",type: SIGNED, size: 16,address: { read: "D950", write: "" } },
  { name: "lowVoltageSideConnected2Power",type: SIGNED, size: 16,address: { read: "D951", write: "" } },
  { name: "reserve",type: UNSIGNED, size: 16,address: { read: "D952", write: "" } },
  { name: "lowVoltageSideConnected3Voltage",type: UNSIGNED, size: 16,address: { read: "D953", write: "" } },
  { name: "lowVoltageSideConnected3Current",type: SIGNED, size: 16,address: { read: "D954", write: "" } },
  { name: "lowVoltageSideConnected3Power",type: SIGNED, size: 16,address: { read: "D955", write: "" } },
  { name: "reserve",type: UNSIGNED, size: 16,address: { read: "D956", write: "" } },
  { name: "lowVoltageSideConnected4Voltage",type: UNSIGNED, size: 16,address: { read: "D957", write: "" } },
  { name: "lowVoltageSideConnected4Current",type: SIGNED, size: 16,address: { read: "D958", write: "" } },
  { name: "lowVoltageSideConnected4Power",type: SIGNED, size: 16,address: { read: "D959", write: "" } },
  { name: "reserve",type: UNSIGNED, size: 16,address: { read: "D95A", write: "" } },
  { name: "lowVoltageSideConnected5Voltage",type: UNSIGNED, size: 16,address: { read: "D95B", write: "" } },
  { name: "lowVoltageSideConnected5Current",type: SIGNED, size: 16,address: { read: "D95C", write: "" } },
  { name: "lowVoltageSideConnected5Power",type: SIGNED, size: 16,address: { read: "D95D", write: "" } },
  { name: "reserve",type: UNSIGNED, size: 16,address: { read: "D95E", write: "" } },
  { name: "lowVoltageSideConnected6Voltage",type: UNSIGNED, size: 16,address: { read: "D95F", write: "" } },
  { name: "lowVoltageSideConnected6Current",type: SIGNED, size: 16,address: { read: "D960", write: "" } },
  { name: "lowVoltageSideConnected6Power",type: SIGNED, size: 16,address: { read: "D961", write: "" } },
  { name: "reserve",type: UNSIGNED, size: 16,address: { read: "D962", write: "" } },
  { name: "deviceStatusByte1",type: UNSIGNED, size: 16,address: { read: "D963", write: "" } },
  { name: "deviceStatusByte2",type: UNSIGNED, size: 16,address: { read: "D964", write: "" } },
  { name: "deviceStatusByte3",type: UNSIGNED, size: 16,address: { read: "D965", write: "" } },
  { name: "deviceStatusByte4",type: UNSIGNED, size: 16,address: { read: "D966", write: "" } },
  { name: "faultByte1",type: UNSIGNED, size: 16,address: { read: "D967", write: "" } },
  { name: "faultByte2System",type: UNSIGNED, size: 16,address: { read: "D968", write: "" } },
  { name: "dcAccess1FaultByte",type: UNSIGNED, size: 16,address: { read: "D969", write: "" } },
  { name: "dcAccess2FaultByte",type: UNSIGNED, size: 16,address: { read: "D96A", write: "" } },
  { name: "dcAccess3FaultByte",type: UNSIGNED, size: 16,address: { read: "D96B", write: "" } },
  { name: "dcAccess4FaultByte",type: UNSIGNED, size: 16,address: { read: "D96C", write: "" } },
  { name: "dcAccess5FaultByte",type: UNSIGNED, size: 16,address: { read: "D96D", write: "" } },
  { name: "dcAccess6FaultByte",type: UNSIGNED, size: 16,address: { read: "D96E", write: "" } },
  { name: "softwareVersionHighBit",type: UNSIGNED, size: 16,address: { read: "D96F", write: "" } },
  { name: "softwareVersionLowBit",type: UNSIGNED, size: 16,address: { read: "D970", write: "" } },
  { name: "hardwareVersionHighBit",type: UNSIGNED, size: 16,address: { read: "D971", write: "" } },
  { name: "hardwareVersionLowBit",type: UNSIGNED, size: 16,address: { read: "D972", write: "" } },
  { name: "batteryStatus",type: UNSIGNED, size: 16,address: { read: "DF60", write: "" } },
  { name: "batteryVoltage",type: UNSIGNED, size: 16,address: { read: "DF61", write: "" } },
  { name: "batteryCurrent",type: SIGNED, size: 16,address: { read: "DF62", write: "" } },
  { name: "batterySOC",type: UNSIGNED, size: 16,address: { read: "DF63", write: "" } },
  { name: "maximumAllowableChargingCurrent",type: UNSIGNED, size: 16,address: { read: "DF64", write: "" } },
  { name: "maximumAllowableDischargingCurrent",type: UNSIGNED, size: 16,address: { read: "DF65", write: "" } },
  { name: "maximumCellVoltage",type: UNSIGNED, size: 16,address: { read: "DF66", write: "" } },
  { name: "minimumCellVoltage",type: UNSIGNED, size: 16,address: { read: "DF67", write: "" } },
  { name: "batteryProtectionEnable",type: UNSIGNED, size: 16,address: { read: "DF70", write: "" } },
  { name: "sOCUpperLimit",type: UNSIGNED, size: 16,address: { read: "DF71", write: "" } },
  { name: "sOCLowerLimit",type: SIGNED, size: 16,address: { read: "DF72", write: "" } },
  { name: "groupVoltageUpperLimit",type: UNSIGNED, size: 16,address: { read: "DF73", write: "" } },
  { name: "groupVoltageLowerLimit",type: UNSIGNED, size: 16,address: { read: "DF74", write: "" } },
  { name: "CellVoltageUpperLimit",type: UNSIGNED, size: 16,address: { read: "DF75", write: "" } },
  { name: "CellVoltageLowerLimit",type: UNSIGNED, size: 16,address: { read: "DF76", write: "" } },
  { name: "batteryAgreement",type: UNSIGNED, size: 16,address: { read: "DF77", write: "" } },  
  // 电表
  { name: "totalElectricityCurrentCoipmbinationActive",type: UNSIGNED, size: 32,address: { read: "8000", write: "" } },
  { name: "spikeElectricityCurrentCoipmbinationActive",type: UNSIGNED, size: 32,address: { read: "8002", write: "" } },
  { name: "peakElectricityCurrentCoipmbinationActive",type: UNSIGNED, size: 32,address: { read: "8004", write: "" } },
  { name: "meanElectricityCurrentCoipmbinationActive",type: UNSIGNED, size: 32,address: { read: "8006", write: "" } },
  { name: "valleyElectricityCurrentCoipmbinationActive",type: UNSIGNED, size: 32,address: { read: "8008", write: "" } },
  { name: "totalElectricityCurrentPositiveActive",type: UNSIGNED, size: 32,address: { read: "800A", write: "" } },
  { name: "spikeElectricityCurrentPositiveActive",type: UNSIGNED, size: 32,address: { read: "800C", write: "" } },
  { name: "peakElectricityCurrentPositiveActive",type: UNSIGNED, size: 32,address: { read: "800E", write: "" } },
  { name: "meanElectricityCurrentPositiveActive",type: UNSIGNED, size: 32,address: { read: "8010", write: "" } },
  { name: "valleyElectricityCurrentPositiveActive",type: UNSIGNED, size: 32,address: { read: "8012", write: "" } },
  { name: "totalElectricityCurrentReverseActive",type: UNSIGNED, size: 32,address: { read: "8014", write: "" } },
  { name: "spikeElectricityCurrentReverseActive",type: UNSIGNED, size: 32,address: { read: "8016", write: "" } },
  { name: "peakElectricityCurrentReverseActive",type: UNSIGNED, size: 32,address: { read: "8018", write: "" } },
  { name: "meanElectricityCurrentReverseActive",type: UNSIGNED, size: 32,address: { read: "801A", write: "" } },
  { name: "valleyElectricityCurrentReverseActive",type: UNSIGNED, size: 32,address: { read: "801C", write: "" } },
  { name: "totalElectricityCurrentCoipmbinationReactive",type: UNSIGNED, size: 32,address: { read: "801E", write: "" } },
  { name: "spikeElectricityCurrentCoipmbinationReactive",type: UNSIGNED, size: 32,address: { read: "8020", write: "" } },
  { name: "peakElectricityCurrentCoipmbinationReactive",type: UNSIGNED, size: 32,address: { read: "8022", write: "" } },
  { name: "meanElectricityCurrentCoipmbinationReactive",type: UNSIGNED, size: 32,address: { read: "8024", write: "" } },
  { name: "valleyElectricityCurrentCoipmbinationReactive",type: UNSIGNED, size: 32,address: { read: "8026", write: "" } },
  { name: "totalElectricityCurrentPositiveReactive",type: UNSIGNED, size: 32,address: { read: "8028", write: "" } },
  { name: "spikeElectricityCurrentPositiveReactive",type: UNSIGNED, size: 32,address: { read: "802A", write: "" } },
  { name: "peakElectricityCurrentPositiveReactive",type: UNSIGNED, size: 32,address: { read: "802C", write: "" } },
  { name: "meanElectricityCurrentPositiveReactive",type: UNSIGNED, size: 32,address: { read: "802E", write: "" } },
  { name: "valleyElectricityCurrentPositiveReactivetype",type: UNSIGNED, size: 32,address: { read: "8030", write: "" } },
  { name: "totalElectricityCurrentReverseReactivetype",type: UNSIGNED, size: 32,address: { read: "8032", write: "" } },
  { name: "spikeElectricityCurrentReverseReactivetype",type: UNSIGNED, size: 32,address: { read: "8034", write: "" } },
  { name: "peakElectricityCurrentReverseReactivetype",type: UNSIGNED, size: 32,address: { read: "8036", write: "" } },
  { name: "meanElectricityCurrentReverseReactivetype",type: UNSIGNED, size: 32,address: { read: "8038", write: "" } },
  { name: "valleyElectricityCurrentReverseReactivetype",type: UNSIGNED, size: 32,address: { read: "803A", write: "" } },
  { name: "timeMinuteSecondtype",type: UNSIGNED, size: 16,address: { read: "8050", write: "" } },
  { name: "timeDayHourtype",type: UNSIGNED, size: 16,address: { read: "8051", write: "" } },
  { name: "timeYearMonthtype",type: UNSIGNED, size: 16,address: { read: "8052", write: "" } },
  { name: "firstLineCommunicationCommunicationAddresstype",type: UNSIGNED, size: 16,address: { read: "8053", write: "" } },
  { name: "firstLineCommunicationBaudRatetype",type: UNSIGNED, size: 16,address: { read: "8054", write: "" } },
  { name: "firstLineCommunicationCheckBittype",type: UNSIGNED, size: 16,address: { read: "8055", write: "" } },
  { name: "firstLineCommunicationstopBittype",type: UNSIGNED, size: 16,address: { read: "8056", write: "" } },
  { name: "secondLineCommunicationCommunicationAddresstype",type: UNSIGNED, size: 16,address: { read: "8057", write: "" } },
  { name: "secondLineCommunicationBaudRatetype",type: UNSIGNED, size: 16,address: { read: "8058", write: "" } },
  { name: "secondLineCommunicationCheckBittype",type: UNSIGNED, size: 16,address: { read: "8059", write: "" } },
  { name: "secondLineCommunicationstopBittype",type: UNSIGNED, size: 16,address: { read: "8060", write: "" } },
  { name: "pulseConsttype",type: UNSIGNED, size: 16,address: { read: "8061", write: "" } },
  { name: "timeTableNumberFirstZonetype",type: UNSIGNED, size: 16,address: { read: "8062", write: "" } },
  { name: "startDateFirstZoneMonthDay",type: UNSIGNED, size: 16,address: { read: "8063", write: "" } },
  { name: "timeTableNumberSecondZone",type: UNSIGNED, size: 16,address: { read: "8064", write: "" } },
  { name: "startDateSecondZoneMonthDay",type: UNSIGNED, size: 16,address: { read: "8065", write: "" } },
  { name: "timeTableNumberThirdZone",type: UNSIGNED, size: 16,address: { read: "8066", write: "" } },
  { name: "startDateThirdZoneMonthDay",type: UNSIGNED, size: 16,address: { read: "8067", write: "" } },
  { name: "timeTableNumberFourthZone",type: UNSIGNED, size: 16,address: { read: "8068", write: "" } },
  { name: "startDateFourthZoneMonthDay",type: UNSIGNED, size: 16,address: { read: "8069", write: "" } },
  { name: "rateNumberFirstTime",type: UNSIGNED, size: 16,address: { read: "8070", write: "" } },
  { name: "startTimeFirstTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8071", write: "" } },
  { name: "rateNumberSecondTime",type: UNSIGNED, size: 16,address: { read: "8072", write: "" } },
  { name: "startTimeSecondTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8073", write: "" } },
  { name: "rateNumberThirdTime",type: UNSIGNED, size: 16,address: { read: "8074", write: "" } },
  { name: "startTimeThirdTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8075", write: "" } },
  { name: "rateNumberFirstTime",type: UNSIGNED, size: 16,address: { read: "8076", write: "" } },
  { name: "startTimeFourthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8077", write: "" } },
  { name: "rateNumberFifthTime",type: UNSIGNED, size: 16,address: { read: "8078", write: "" } },
  { name: "startTimeFifthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8079", write: "" } },
  { name: "rateNumberSixthTime",type: UNSIGNED, size: 16,address: { read: "807A", write: "" } },
  { name: "startTimeSixthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "807B", write: "" } },
  { name: "rateNumberSeventhTime",type: UNSIGNED, size: 16,address: { read: "807C", write: "" } },
  { name: "startTimeSeventhTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "807D", write: "" } },
  { name: "rateNumber_eighthTime",type: UNSIGNED, size: 16,address: { read: "807E", write: "" } },
  { name: "startTime_eighthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "807F", write: "" } },
  { name: "rateNumberNinthTime",type: UNSIGNED, size: 16,address: { read: "8080", write: "" } },
  { name: "startTimeNinthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8081", write: "" } },
  { name: "rateNumberTenthTime",type: UNSIGNED, size: 16,address: { read: "8082", write: "" } },
  { name: "startTimeTenthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8083", write: "" } },
  { name: "rateNumberEleventhTime",type: UNSIGNED, size: 16,address: { read: "8084", write: "" } },
  { name: "startTimeEleventhTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8085", write: "" } },
  { name: "rateNumberTwelfthTime",type: UNSIGNED, size: 16,address: { read: "8086", write: "" } },
  { name: "startTimeTwelfthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8087", write: "" } },
  { name: "rateNumberThirteenthTime",type: UNSIGNED, size: 16,address: { read: "8088", write: "" } },
  { name: "startTimeThirteenthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8089", write: "" } },
  { name: "rateNumberFourteenthTime",type: UNSIGNED, size: 16,address: { read: "808A", write: "" } },
  { name: "startTimeFourteenthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "808B", write: "" } },
  { name: "rateNumberFirstTime",type: UNSIGNED, size: 16,address: { read: "8090", write: "" } },
  { name: "startTimeFirstTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8091", write: "" } },
  { name: "rateNumberSecondTime",type: UNSIGNED, size: 16,address: { read: "8092", write: "" } },
  { name: "startTimeSecondTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8093", write: "" } },
  { name: "rateNumberThirdTime",type: UNSIGNED, size: 16,address: { read: "8094", write: "" } },
  { name: "startTimeThirdTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8095", write: "" } },
  { name: "rateNumberFirstTime",type: UNSIGNED, size: 16,address: { read: "8096", write: "" } },
  { name: "startTimeFourthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8097", write: "" } },
  { name: "rateNumberFifthTime",type: UNSIGNED, size: 16,address: { read: "8098", write: "" } },
  { name: "startTimeFifthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8099", write: "" } },
  { name: "rateNumberSixthTime",type: UNSIGNED, size: 16,address: { read: "809A", write: "" } },
  { name: "startTimeSixthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "809B", write: "" } },
  { name: "rateNumberSeventhTime",type: UNSIGNED, size: 16,address: { read: "809C", write: "" } },
  { name: "startTimeSeventhTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "809D", write: "" } },
  { name: "rateNumber_eighthTime",type: UNSIGNED, size: 16,address: { read: "809E", write: "" } },
  { name: "startTime_eighthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "809F", write: "" } },
  { name: "rateNumberNinthTime",type: UNSIGNED, size: 16,address: { read: "80A0", write: "" } },
  { name: "startTimeNinthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "80A1", write: "" } },
  { name: "rateNumberTenthTime",type: UNSIGNED, size: 16,address: { read: "80A2", write: "" } },
  { name: "startTimeTenthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "80A3", write: "" } },
  { name: "rateNumberEleventhTime",type: UNSIGNED, size: 16,address: { read: "80A4", write: "" } },
  { name: "startTimeEleventhTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "80A5", write: "" } },
  { name: "rateNumberTwelfthTime",type: UNSIGNED, size: 16,address: { read: "80A6", write: "" } },
  { name: "startTimeTwelfthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "80A7", write: "" } },
  { name: "rateNumberThirteenthTime",type: UNSIGNED, size: 16,address: { read: "80A8", write: "" } },
  { name: "startTimeThirteenthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "80A9", write: "" } },
  { name: "rateNumberFourteenthTime",type: UNSIGNED, size: 16,address: { read: "80AA", write: "" } },
  { name: "startTimeFourteenthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "80AB", write: "" } },
  { name: "voltageAPhase",type: UNSIGNED, size: 16,address: { read: "80B0", write: "" } },
  { name: "voltageBPhase",type: UNSIGNED, size: 16,address: { read: "80B1", write: "" } },
  { name: "voltageCPhase",type: UNSIGNED, size: 16,address: { read: "80B2", write: "" } },
  { name: "currentAPhase",type: UNSIGNED, size: 16,address: { read: "80B3", write: "" } },
  { name: "currentBPhase",type: UNSIGNED, size: 16,address: { read: "80B4", write: "" } },
  { name: "currentCPhase",type: UNSIGNED, size: 16,address: { read: "80B5", write: "" } },
  { name: "frequency",type: UNSIGNED, size: 16,address: { read: "80B6", write: "" } },
  { name: "voltageABLine",type: UNSIGNED, size: 16,address: { read: "80B7", write: "" } },
  { name: "voltageBCLine",type: UNSIGNED, size: 16,address: { read: "80B8", write: "" } },
  { name: "voltageACLine",type: UNSIGNED, size: 16,address: { read: "80B9", write: "" } },
  { name: "positiveActiveMaximumDemand",type: UNSIGNED, size: 16,address: { read: "80BA", write: "" } },
  { name: "occurTimePositiveActiveMaximumDemandHourMinute",type: UNSIGNED, size: 16,address: { read: "80BB", write: "" } },
  { name: "occurTimePositiveActiveMaximumDemandMonthDay",type: UNSIGNED, size: 16,address: { read: "80BC", write: "" } },
  { name: "reverseActiveMaximumDemand",type: UNSIGNED, size: 16,address: { read: "80BD", write: "" } },
  { name: "occurTimeReverseActiveMaximumDemandHourMinute",type: UNSIGNED, size: 16,address: { read: "80BE", write: "" } },
  { name: "occurTimeReverseActiveMaximumDemandMonthDay",type: UNSIGNED, size: 16,address: { read: "80BF", write: "" } },
  { name: "positiveReactiveMaximumDemand",type: UNSIGNED, size: 16,address: { read: "80C0", write: "" } },
  { name: "occurTimePositiveReactiveMaximumDemandHourMinute",type: UNSIGNED, size: 16,address: { read: "80C1", write: "" } },
  { name: "occurTimePositiveReactiveMaximumDemandMonthDay",type: UNSIGNED, size: 16,address: { read: "80C2", write: "" } },
  { name: "reverseReactiveMaximumDemand",type: UNSIGNED, size: 16,address: { read: "80C3", write: "" } },
  { name: "occurTimeReverseReactiveMaximumDemandHourMinute",type: UNSIGNED, size: 16,address: { read: "80C4", write: "" } },
  { name: "occurTimeReverseReactiveMaximumDemandMonthDay",type: UNSIGNED, size: 16,address: { read: "80C5", write: "" } },
  { name: "positiveActiveElectricityAPhase",type: UNSIGNED, size: 32,address: { read: "80C6", write: "" } },
  { name: "positiveActiveElectricityBPhase",type: UNSIGNED, size: 32,address: { read: "80C8", write: "" } },
  { name: "positiveActiveElectricityCPhase",type: UNSIGNED, size: 32,address: { read: "80CA", write: "" } },
  { name: "voltageRatioPT",type: UNSIGNED, size: 16,address: { read: "80CC", write: "" } },
  { name: "currentRatioCT",type: UNSIGNED, size: 16,address: { read: "80CD", write: "" } },
  { name: "didoStateNoVoltageState",type: UNSIGNED, size: 16,address: { read: "80CE", write: "" } },
  { name: "runningState",type: UNSIGNED, size: 16,address: { read: "80CF", write: "" } },
  { name: "zeroSequenceCurrent",type: UNSIGNED, size: 16,address: { read: "80D0", write: "" } },
  { name: "voltageUnbalanceDegree",type: SIGNED, size: 16,address: { read: "80D1", write: "" } },
  { name: "currentUnbalanceDegree",type: SIGNED, size: 16,address: { read: "80D2", write: "" } },
  { name: "activePowerAPhase",type: SIGNED, size: 32,address: { read: "80D3", write: "" } },
  { name: "activePowerBPhase",type: SIGNED, size: 32,address: { read: "80D5", write: "" } },
  { name: "activePowerCPhase",type: SIGNED, size: 32,address: { read: "80D7", write: "" } },
  { name: "totalActivePower",type: SIGNED, size: 32,address: { read: "80D9", write: "" } },
  { name: "reactivePowerAPhase",type: SIGNED, size: 32,address: { read: "80DB", write: "" } },
  { name: "reactivePowerBPhase",type: SIGNED, size: 32,address: { read: "80DD", write: "" } },
  { name: "reactivePowerCPhase",type: SIGNED, size: 32,address: { read: "80DF", write: "" } },
  { name: "totalReactivePower",type: SIGNED, size: 32,address: { read: "80E1", write: "" } },
  { name: "apparentPowerAPhase",type: UNSIGNED, size: 32,address: { read: "80E3", write: "" } },
  { name: "apparentPowerBPhase",type: UNSIGNED, size: 32,address: { read: "80E5", write: "" } },
  { name: "apparentPowerCPhase",type: UNSIGNED, size: 32,address: { read: "80E7", write: "" } },
  { name: "totalApparentPower",type: UNSIGNED, size: 32,address: { read: "80E9", write: "" } },
  { name: "powerFactorAPhase",type: SIGNED, size: 16,address: { read: "80EB", write: "" } },
  { name: "powerFactorBPhase",type: SIGNED, size: 16,address: { read: "80EC", write: "" } },
  { name: "powerFactorCPhase",type: SIGNED, size: 16,address: { read: "80ED", write: "" } },
  { name: "totalPowerFactor",type: SIGNED, size: 16,address: { read: "80EE", write: "" } },
  { name: "maximumPositiveActivePowerDemandToday",type: UNSIGNED, size: 16,address: { read: "80EF", write: "" } },
  { name: "occurrenceTimeTodayMaximumPositiveActivePowerDemandHourMinute",type: UNSIGNED, size: 16,address: { read: "80F0", write: "" } },
  { name: "maximumReverseActivePowerDemandToday",type: UNSIGNED, size: 16,address: { read: "80F1", write: "" } },
  { name: "occurrenceTimeTodayMaximumReverseActivePowerDemandHourMinute",type: UNSIGNED, size: 16,address: { read: "80F2", write: "" } },
  { name: "maximumPositiveReactivePowerDemandToday",type: UNSIGNED, size: 16,address: { read: "80F3", write: "" } },
  { name: "occurrenceTimeTodayMaximumPositiveReactivePowerDemandHourMinute",type: UNSIGNED, size: 16,address: { read: "80F4", write: "" } },
  { name: "maximumReverseReactivePowerDemandToday",type: UNSIGNED, size: 16,address: { read: "80F5", write: "" } },
  { name: "occurrenceTimeTodayMaximumReverseReactivePowerDemandHourMinute",type: UNSIGNED, size: 16,address: { read: "80F6", write: "" } },
  { name: "maximumPositiveActivePowerDemandLastOneDay",type: UNSIGNED, size: 16,address: { read: "8101", write: "" } },
  { name: "occurrenceTimeLastOneDayMaximumPositiveActivePowerDemandHourMinute",type: UNSIGNED, size: 16,address: { read: "8102", write: "" } },
  { name: "maximumReverseActivePowerDemandLastOneDay",type: UNSIGNED, size: 16,address: { read: "8103", write: "" } },
  { name: "occurrenceTimeLastOneDayMaximumReverseActivePowerDemandHourMinute",type: UNSIGNED, size: 16,address: { read: "8104", write: "" } },
  { name: "maximumPositiveReactivePowerDemandLastOneDay",type: UNSIGNED, size: 16,address: { read: "8105", write: "" } },
  { name: "occurrenceTimeLastOneDayMaximumPositiveReactivePowerDemandHourMinute",type: UNSIGNED, size: 16,address: { read: "8106", write: "" } },
  { name: "maximumReverseReactivePowerDemandLastOneDay",type: UNSIGNED, size: 16,address: { read: "8107", write: "" } },
  { name: "occurrenceTimeLastOneDayMaximumReverseReactivePowerDemandHourMinute",type: UNSIGNED, size: 16,address: { read: "8108", write: "" } },
  { name: "maximumPositiveActivePowerDemandLastTwoDay",type: UNSIGNED, size: 16,address: { read: "8113", write: "" } },
  { name: "occurrenceTimeLastTwoDayMaximumPositiveActivePowerDemandHourMinute",type: UNSIGNED, size: 16,address: { read: "8114", write: "" } },
  { name: "maximumReverseActivePowerDemandLastTwoDay",type: UNSIGNED, size: 16,address: { read: "8115", write: "" } },
  { name: "occurrenceTimeLastTwoDayMaximumReverseActivePowerDemandHourMinute",type: UNSIGNED, size: 16,address: { read: "8116", write: "" } },
  { name: "maximumPositiveReactivePowerDemandLastTwoDay",type: UNSIGNED, size: 16,address: { read: "8117", write: "" } },
  { name: "occurrenceTimeLastTwoDayMaximumPositiveReactivePowerDemandHourMinute",type: UNSIGNED, size: 16,address: { read: "8118", write: "" } },
  { name: "maximumReverseReactivePowerDemandLastTwoDay",type: UNSIGNED, size: 16,address: { read: "8119", write: "" } },
  { name: "occurrenceTimeLastTwoDayMaximumReverseReactivePowerDemandHourMinute",type: UNSIGNED, size: 16,address: { read: "811A", write: "" } },
  { name: "currentPositiveActiveDemand",type: UNSIGNED, size: 16,address: { read: "812B", write: "" } },
  { name: "currentReverseActiveDemand",type: UNSIGNED, size: 16,address: { read: "812C", write: "" } },
  { name: "currentPositiveReactiveDemand",type: UNSIGNED, size: 16,address: { read: "812D", write: "" } },
  { name: "currentReverseReactiveDemand",type: UNSIGNED, size: 16,address: { read: "812E", write: "" } },
  { name: "maximumVoltageAPhase",type: UNSIGNED, size: 16,address: { read: "812F", write: "" } },
  { name: "occurrenceTimeAPhaseMaximumVoltageMonthDay",type: UNSIGNED, size: 16,address: { read: "8130", write: "" } },
  { name: "occurrenceTimeAPhaseMaximumVoltageHourMinute",type: UNSIGNED, size: 16,address: { read: "8131", write: "" } },
  { name: "maximumVoltageBPhase",type: UNSIGNED, size: 16,address: { read: "8132", write: "" } },
  { name: "occurrenceTimeBPhaseMaximumVoltageMonthDay",type: UNSIGNED, size: 16,address: { read: "8133", write: "" } },
  { name: "occurrenceTimeBPhaseMaximumVoltageHourMinute",type: UNSIGNED, size: 16,address: { read: "8134", write: "" } },
  { name: "maximumVoltageCPhase",type: UNSIGNED, size: 16,address: { read: "8135", write: "" } },
  { name: "occurrenceTimeCPhaseMaximumVoltageMonthDay",type: UNSIGNED, size: 16,address: { read: "8136", write: "" } },
  { name: "occurrenceTimeCPhaseMaximumVoltageHourMinute",type: UNSIGNED, size: 16,address: { read: "8137", write: "" } },
  { name: "maximumCurrentAPhase",type: UNSIGNED, size: 16,address: { read: "8138", write: "" } },
  { name: "occurrenceTimeAPhaseMaximumCurrentMonthDay",type: UNSIGNED, size: 16,address: { read: "8139", write: "" } },
  { name: "occurrenceTimeAPhaseMaximumCurrentHourMinute",type: UNSIGNED, size: 16,address: { read: "813A", write: "" } },
  { name: "maximumCurrentBPhase",type: UNSIGNED, size: 16,address: { read: "813B", write: "" } },
  { name: "occurrenceTimeBPhaseMaximumCurrentMonthDay",type: UNSIGNED, size: 16,address: { read: "813C", write: "" } },
  { name: "occurrenceTimeBPhaseMaximumCurrentHourMinute",type: UNSIGNED, size: 16,address: { read: "813D", write: "" } },
  { name: "maximumCurrentCPhase",type: UNSIGNED, size: 16,address: { read: "813E", write: "" } },
  { name: "occurrenceTimeCPhaseMaximumCurrentMonthDay",type: UNSIGNED, size: 16,address: { read: "813F", write: "" } },
  { name: "occurrenceTimeCPhaseMaximumCurrentHourMinute",type: UNSIGNED, size: 16,address: { read: "8140", write: "" } },
  { name: "maximumActivePowerAPhase",type: UNSIGNED, size: 32,address: { read: "8141", write: "" } },
  { name: "occurrenceTimeAPhaseMaximumActivePowerMonthDay",type: UNSIGNED, size: 16,address: { read: "8143", write: "" } },
  { name: "occurrenceTimeAPhaseMaximumActivePowerHourMinute",type: UNSIGNED, size: 16,address: { read: "8144", write: "" } },
  { name: "maximumActivePowerBPhase",type: UNSIGNED, size: 32,address: { read: "8145", write: "" } },
  { name: "occurrenceTimeBPhaseMaximumActivePowerMonthDay",type: UNSIGNED, size: 16,address: { read: "8147", write: "" } },
  { name: "occurrenceTimeBPhaseMaximumActivePowerHourMinute",type: UNSIGNED, size: 16,address: { read: "8148", write: "" } },
  { name: "maximumActivePowerCPhase",type: UNSIGNED, size: 32,address: { read: "8149", write: "" } },
  { name: "occurrenceTimeCPhaseMaximumActivePowerMonthDay",type: UNSIGNED, size: 16,address: { read: "814B", write: "" } },
  { name: "occurrenceTimeCPhaseMaximumActivePowerHourMinute",type: UNSIGNED, size: 16,address: { read: "814C", write: "" } },
  { name: "maximumTotalActivePower",type: UNSIGNED, size: 32,address: { read: "814D", write: "" } },
  { name: "occurrenceTimeTotalMaximumActivePowerMonthDay",type: UNSIGNED, size: 16,address: { read: "814F", write: "" } },
  { name: "occurrenceTimeTotalMaximumActivePowerHourMinute",type: UNSIGNED, size: 16,address: { read: "8150", write: "" } },
  { name: "maximumReactivePowerAPhase",type: UNSIGNED, size: 32,address: { read: "8151", write: "" } },
  { name: "occurrenceTimeAPhaseMaximumReactivePowerMonthDay",type: UNSIGNED, size: 16,address: { read: "8153", write: "" } },
  { name: "occurrenceTimeAPhaseMaximumReactivePowerHourMinute",type: UNSIGNED, size: 16,address: { read: "8154", write: "" } },
  { name: "maximumReactivePowerBPhase",type: UNSIGNED, size: 32,address: { read: "8155", write: "" } },
  { name: "occurrenceTimeBPhaseMaximumReactivePowerMonthDay",type: UNSIGNED, size: 16,address: { read: "8157", write: "" } },
  { name: "occurrenceTimeBPhaseMaximumReactivePowerHourMinute",type: UNSIGNED, size: 16,address: { read: "8158", write: "" } },
  { name: "maximumReactivePowerCPhase",type: UNSIGNED, size: 32,address: { read: "8159", write: "" } },
  { name: "occurrenceTimeCPhaseMaximumReactivePowerMonthDay",type: UNSIGNED, size: 16,address: { read: "815B", write: "" } },
  { name: "occurrenceTimeCPhaseMaximumReactivePowerHourMinute",type: UNSIGNED, size: 16,address: { read: "815C", write: "" } },
  { name: "maximumTotalReactivePower",type: UNSIGNED, size: 32,address: { read: "815D", write: "" } },
  { name: "occurrenceTimeTotalMaximumReactivePowerMonthDay",type: UNSIGNED, size: 16,address: { read: "815F", write: "" } },
  { name: "occurrenceTimeTotalMaximumReactivePowerHourMinute",type: UNSIGNED, size: 16,address: { read: "8160", write: "" } },
  { name: "maximumApparentPowerAPhase",type: UNSIGNED, size: 32,address: { read: "8161", write: "" } },
  { name: "occurrenceTimeAPhaseMaximumApparentPowerMonthDay",type: UNSIGNED, size: 16,address: { read: "8163", write: "" } },
  { name: "occurrenceTimeAPhaseMaximumApparentPowerHourMinute",type: UNSIGNED, size: 16,address: { read: "8164", write: "" } },
  { name: "maximumApparentPowerBPhase",type: UNSIGNED, size: 32,address: { read: "8165", write: "" } },
  { name: "occurrenceTimeBPhaseMaximumApparentPowerMonthDay",type: UNSIGNED, size: 16,address: { read: "8167", write: "" } },
  { name: "occurrenceTimeBPhaseMaximumApparentPowerHourMinute",type: UNSIGNED, size: 16,address: { read: "8168", write: "" } },
  { name: "maximumApparentPowerCPhase",type: UNSIGNED, size: 32,address: { read: "8169", write: "" } },
  { name: "occurrenceTimeCPhaseMaximumApparentPowerMonthDay",type: UNSIGNED, size: 16,address: { read: "816B", write: "" } },
  { name: "occurrenceTimeCPhaseMaximumApparentPowerHourMinute",type: UNSIGNED, size: 16,address: { read: "816C", write: "" } },
  { name: "maximumTotalApparentPower",type: UNSIGNED, size: 32,address: { read: "816D", write: "" } },
  { name: "occurrenceTimeTotalMaximumApparentPowerMonthDay",type: UNSIGNED, size: 16,address: { read: "816F", write: "" } },
  { name: "occurrenceTimeTotalMaximumApparentPowerHourMinute",type: UNSIGNED, size: 16,address: { read: "8170", write: "" } },
  { name: "minimumVoltageAPhase",type: UNSIGNED, size: 16,address: { read: "8171", write: "" } },
  { name: "occurrenceTimeAPhaseMinimumVoltageMonthDay",type: UNSIGNED, size: 16,address: { read: "8172", write: "" } },
  { name: "occurrenceTimeAPhaseMinimumVoltageHourMinute",type: UNSIGNED, size: 16,address: { read: "8173", write: "" } },
  { name: "minimumVoltageBPhase",type: UNSIGNED, size: 16,address: { read: "8174", write: "" } },
  { name: "occurrenceTimeBPhaseMinimumVoltageMonthDay",type: UNSIGNED, size: 16,address: { read: "8175", write: "" } },
  { name: "occurrenceTimeBPhaseMinimumVoltageHourMinute",type: UNSIGNED, size: 16,address: { read: "8176", write: "" } },
  { name: "minimumVoltageCPhase",type: UNSIGNED, size: 16,address: { read: "8177", write: "" } },
  { name: "occurrenceTimeCPhaseMinimumVoltageMonthDay",type: UNSIGNED, size: 16,address: { read: "8178", write: "" } },
  { name: "occurrenceTimeCPhaseMinimumVoltageHourMinute",type: UNSIGNED, size: 16,address: { read: "8179", write: "" } },
  { name: "minimumCurrentAPhase",type: UNSIGNED, size: 16,address: { read: "817A", write: "" } },
  { name: "occurrenceTimeAPhaseMinimumCurrentMonthDay",type: UNSIGNED, size: 16,address: { read: "817B", write: "" } },
  { name: "occurrenceTimeAPhaseMinimumCurrentHourMinute",type: UNSIGNED, size: 16,address: { read: "817C", write: "" } },
  { name: "minimumCurrentBPhase",type: UNSIGNED, size: 16,address: { read: "817D", write: "" } },
  { name: "occurrenceTimeBPhaseMinimumCurrentMonthDay",type: UNSIGNED, size: 16,address: { read: "817E", write: "" } },
  { name: "occurrenceTimeBPhaseMinimumCurrentHourMinute",type: UNSIGNED, size: 16,address: { read: "817F", write: "" } },
  { name: "minimumCurrentCPhase",type: UNSIGNED, size: 16,address: { read: "8180", write: "" } },
  { name: "occurrenceTimeCPhaseMinimumCurrentMonthDay",type: UNSIGNED, size: 16,address: { read: "8181", write: "" } },
  { name: "occurrenceTimeCPhaseMinimumCurrentHourMinute",type: UNSIGNED, size: 16,address: { read: "8182", write: "" } },
  { name: "minimumActivePowerAPhase",type: UNSIGNED, size: 32,address: { read: "8183", write: "" } },
  { name: "occurrenceTimeAPhaseMinimumActivePowerMonthDay",type: UNSIGNED, size: 16,address: { read: "8185", write: "" } },
  { name: "occurrenceTimeAPhaseMinimumActivePowerHourMinute",type: UNSIGNED, size: 16,address: { read: "8186", write: "" } },
  { name: "minimumActivePowerBPhase",type: UNSIGNED, size: 32,address: { read: "8187", write: "" } },
  { name: "occurrenceTimeBPhaseMinimumActivePowerMonthDay",type: UNSIGNED, size: 16,address: { read: "8189", write: "" } },
  { name: "occurrenceTimeBPhaseMinimumActivePowerHourMinute",type: UNSIGNED, size: 16,address: { read: "818A", write: "" } },
  { name: "minimumActivePowerCPhase",type: UNSIGNED, size: 32,address: { read: "818B", write: "" } },
  { name: "occurrenceTimeCPhaseMinimumActivePowerMonthDay",type: UNSIGNED, size: 16,address: { read: "818D", write: "" } },
  { name: "occurrenceTimeCPhaseMinimumActivePowerHourMinute",type: UNSIGNED, size: 16,address: { read: "818E", write: "" } },
  { name: "minimumTotalActivePower",type: UNSIGNED, size: 32,address: { read: "818F", write: "" } },
  { name: "occurrenceTimeTotalMinimumActivePowerMonthDay",type: UNSIGNED, size: 16,address: { read: "8191", write: "" } },
  { name: "occurrenceTimeTotalMinimumActivePowerHourMinute",type: UNSIGNED, size: 16,address: { read: "8192", write: "" } },
  { name: "minimumReactivePowerAPhase",type: UNSIGNED, size: 32,address: { read: "8193", write: "" } },
  { name: "occurrenceTimeAPhaseMinimumReactivePowerMonthDay",type: UNSIGNED, size: 16,address: { read: "8195", write: "" } },
  { name: "occurrenceTimeAPhaseMinimumReactivePowerHourMinute",type: UNSIGNED, size: 16,address: { read: "8196", write: "" } },
  { name: "minimumReactivePowerBPhase",type: UNSIGNED, size: 32,address: { read: "8197", write: "" } },
  { name: "occurrenceTimeBPhaseMinimumReactivePowerMonthDay",type: UNSIGNED, size: 16,address: { read: "8199", write: "" } },
  { name: "occurrenceTimeBPhaseMinimumReactivePowerHourMinute",type: UNSIGNED, size: 16,address: { read: "819A", write: "" } },
  { name: "minimumReactivePowerCPhase",type: UNSIGNED, size: 32,address: { read: "819B", write: "" } },
  { name: "occurrenceTimeCPhaseMinimumReactivePowerMonthDay",type: UNSIGNED, size: 16,address: { read: "819D", write: "" } },
  { name: "occurrenceTimeCPhaseMinimumReactivePowerHourMinute",type: UNSIGNED, size: 16,address: { read: "819E", write: "" } },
  { name: "minimumTotalReactivePower",type: UNSIGNED, size: 32,address: { read: "819F", write: "" } },
  { name: "occurrenceTimeTotalMinimumReactivePowerMonthDay",type: UNSIGNED, size: 16,address: { read: "81A1", write: "" } },
  { name: "occurrenceTimeTotalMinimumReactivePowerHourMinute",type: UNSIGNED, size: 16,address: { read: "81A2", write: "" } },
  { name: "minimumApparentPowerAPhase",type: UNSIGNED, size: 32,address: { read: "81A3", write: "" } },
  { name: "occurrenceTimeAPhaseMinimumApparentPowerMonthDay",type: UNSIGNED, size: 16,address: { read: "81A5", write: "" } },
  { name: "occurrenceTimeAPhaseMinimumApparentPowerHourMinute",type: UNSIGNED, size: 16,address: { read: "81A6", write: "" } },
  { name: "minimumApparentPowerBPhase",type: UNSIGNED, size: 32,address: { read: "81A7", write: "" } },
  { name: "occurrenceTimeBPhaseMinimumApparentPowerMonthDay",type: UNSIGNED, size: 16,address: { read: "81A9", write: "" } },
  { name: "occurrenceTimeBPhaseMinimumApparentPowerHourMinute",type: UNSIGNED, size: 16,address: { read: "81AA", write: "" } },
  { name: "minimumApparentPowerCPhase",type: UNSIGNED, size: 32,address: { read: "81AB", write: "" } },
  { name: "occurrenceTimeCPhaseMinimumApparentPowerMonthDay",type: UNSIGNED, size: 16,address: { read: "81AD", write: "" } },
  { name: "occurrenceTimeCPhaseMinimumApparentPowerHourMinute",type: UNSIGNED, size: 16,address: { read: "81AE", write: "" } },
  { name: "minimumTotalApparentPower",type: UNSIGNED, size: 32,address: { read: "81AF", write: "" } },
  { name: "occurrenceTimeTotalMinimumApparentPowerMonthDay",type: UNSIGNED, size: 16,address: { read: "81B0", write: "" } },
  { name: "occurrenceTimeTotalMinimumApparentPowerHourMinute",type: UNSIGNED, size: 16,address: { read: "81B1", write: "" } },
  // 第一套时段表
  { name: "firstTimeTable_rateNumberFirstTime",type: UNSIGNED, size: 16,address: { read: "8070", write: "" } },
  { name: "firstTimeTable_startTimeFirstTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8071", write: "" } },
  { name: "firstTimeTable_rateNumberSecondTime",type: UNSIGNED, size: 16,address: { read: "8072", write: "" } },
  { name: "firstTimeTable_startTimeSecondTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8073", write: "" } },
  { name: "firstTimeTable_rateNumberThirdTime",type: UNSIGNED, size: 16,address: { read: "8074", write: "" } },
  { name: "firstTimeTable_startTimeThirdTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8075", write: "" } },
  { name: "firstTimeTable_rateNumberFourthTime",type: UNSIGNED, size: 16,address: { read: "8076", write: "" } },
  { name: "firstTimeTable_startTimeFourthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8077", write: "" } },
  { name: "firstTimeTable_rateNumberFifthTime",type: UNSIGNED, size: 16,address: { read: "8078", write: "" } },
  { name: "firstTimeTable_startTimeFifthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8079", write: "" } },
  { name: "firstTimeTable_rateNumberSixthTime",type: UNSIGNED, size: 16,address: { read: "81EA", write: "" } },
  { name: "firstTimeTable_startTimeSixthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81EB", write: "" } },
  { name: "firstTimeTable_rateNumberSeventhTime",type: UNSIGNED, size: 16,address: { read: "81EC", write: "" } },
  { name: "firstTimeTable_startTimeSeventhTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81ED", write: "" } },
  { name: "firstTimeTable_rateNumberEighthTime",type: UNSIGNED, size: 16,address: { read: "81EE", write: "" } },
  { name: "firstTimeTable_startTimeEighthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81EF", write: "" } },
  { name: "firstTimeTable_rateNumberNinthTime",type: UNSIGNED, size: 16,address: { read: "81F0", write: "" } },
  { name: "firstTimeTable_startTimeNinthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F1", write: "" } },
  { name: "firstTimeTable_rateNumberTenthTime",type: UNSIGNED, size: 16,address: { read: "81F2", write: "" } },
  { name: "firstTimeTable_startTimeTenthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F3", write: "" } },
  { name: "firstTimeTable_rateNumberEleventhTime",type: UNSIGNED, size: 16,address: { read: "81F4", write: "" } },
  { name: "firstTimeTable_startTimeEleventhTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F5", write: "" } },
  { name: "firstTimeTable_rateNumberTwelfthTime",type: UNSIGNED, size: 16,address: { read: "81F6", write: "" } },
  { name: "firstTimeTable_startTimeTwelfthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F7", write: "" } },
  { name: "firstTimeTable_rateNumberThirteenthTime",type: UNSIGNED, size: 16,address: { read: "81F8", write: "" } },
  { name: "firstTimeTable_startTimeThirteenthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F9", write: "" } },
  { name: "firstTimeTable_rateNumberFourteenthTime",type: UNSIGNED, size: 16,address: { read: "81FA", write: "" } },
  { name: "firstTimeTable_startTimeFourteenthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81FB", write: "" } },  
  // 第二套时段表
  { name: "secondTimeTable_rateNumberFirstTime",type: UNSIGNED, size: 16,address: { read: "8090", write: "" } },
  { name: "secondTimeTable_startTimeFirstTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8091", write: "" } },
  { name: "secondTimeTable_rateNumberSecondTime",type: UNSIGNED, size: 16,address: { read: "8092", write: "" } },
  { name: "secondTimeTable_startTimeSecondTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8093", write: "" } },
  { name: "secondTimeTable_rateNumberThirdTime",type: UNSIGNED, size: 16,address: { read: "8094", write: "" } },
  { name: "secondTimeTable_startTimeThirdTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8095", write: "" } },
  { name: "secondTimeTable_rateNumberFourthTime",type: UNSIGNED, size: 16,address: { read: "8096", write: "" } },
  { name: "secondTimeTable_startTimeFourthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8097", write: "" } },
  { name: "secondTimeTable_rateNumberFifthTime",type: UNSIGNED, size: 16,address: { read: "8098", write: "" } },
  { name: "secondTimeTable_startTimeFifthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "8099", write: "" } },
  { name: "secondTimeTable_rateNumberSixthTime",type: UNSIGNED, size: 16,address: { read: "81EA", write: "" } },
  { name: "secondTimeTable_startTimeSixthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81EB", write: "" } },
  { name: "secondTimeTable_rateNumberSeventhTime",type: UNSIGNED, size: 16,address: { read: "81EC", write: "" } },
  { name: "secondTimeTable_startTimeSeventhTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81ED", write: "" } },
  { name: "secondTimeTable_rateNumberEighthTime",type: UNSIGNED, size: 16,address: { read: "81EE", write: "" } },
  { name: "secondTimeTable_startTimeEighthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81EF", write: "" } },
  { name: "secondTimeTable_rateNumberNinthTime",type: UNSIGNED, size: 16,address: { read: "81F0", write: "" } },
  { name: "secondTimeTable_startTimeNinthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F1", write: "" } },
  { name: "secondTimeTable_rateNumberTenthTime",type: UNSIGNED, size: 16,address: { read: "81F2", write: "" } },
  { name: "secondTimeTable_startTimeTenthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F3", write: "" } },
  { name: "secondTimeTable_rateNumberEleventhTime",type: UNSIGNED, size: 16,address: { read: "81F4", write: "" } },
  { name: "secondTimeTable_startTimeEleventhTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F5", write: "" } },
  { name: "secondTimeTable_rateNumberTwelfthTime",type: UNSIGNED, size: 16,address: { read: "81F6", write: "" } },
  { name: "secondTimeTable_startTimeTwelfthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F7", write: "" } },
  { name: "secondTimeTable_rateNumberThirteenthTime",type: UNSIGNED, size: 16,address: { read: "81F8", write: "" } },
  { name: "secondTimeTable_startTimeThirteenthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F9", write: "" } },
  { name: "secondTimeTable_rateNumberFourteenthTime",type: UNSIGNED, size: 16,address: { read: "81FA", write: "" } },
  { name: "secondTimeTable_startTimeFourteenthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81FB", write: "" } },  
  // 第三套时段表
  { name: "thirdTimeTable_rateNumberFirstTime",type: UNSIGNED, size: 16,address: { read: "81C0", write: "" } },
  { name: "thirdTimeTable_startTimeFirstTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81C1", write: "" } },
  { name: "thirdTimeTable_rateNumberSecondTime",type: UNSIGNED, size: 16,address: { read: "81C2", write: "" } },
  { name: "thirdTimeTable_startTimeSecondTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81C3", write: "" } },
  { name: "thirdTimeTable_rateNumberThirdTime",type: UNSIGNED, size: 16,address: { read: "81C4", write: "" } },
  { name: "thirdTimeTable_startTimeThirdTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81C5", write: "" } },
  { name: "thirdTimeTable_rateNumberFourthTime",type: UNSIGNED, size: 16,address: { read: "81C6", write: "" } },
  { name: "thirdTimeTable_startTimeFourthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81C7", write: "" } },
  { name: "thirdTimeTable_rateNumberFifthTime",type: UNSIGNED, size: 16,address: { read: "81C8", write: "" } },
  { name: "thirdTimeTable_startTimeFifthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81C9", write: "" } },
  { name: "thirdTimeTable_rateNumberSixthTime",type: UNSIGNED, size: 16,address: { read: "81EA", write: "" } },
  { name: "thirdTimeTable_startTimeSixthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81EB", write: "" } },
  { name: "thirdTimeTable_rateNumberSeventhTime",type: UNSIGNED, size: 16,address: { read: "81EC", write: "" } },
  { name: "thirdTimeTable_startTimeSeventhTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81ED", write: "" } },
  { name: "thirdTimeTable_rateNumberEighthTime",type: UNSIGNED, size: 16,address: { read: "81EE", write: "" } },
  { name: "thirdTimeTable_startTimeEighthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81EF", write: "" } },
  { name: "thirdTimeTable_rateNumberNinthTime",type: UNSIGNED, size: 16,address: { read: "81F0", write: "" } },
  { name: "thirdTimeTable_startTimeNinthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F1", write: "" } },
  { name: "thirdTimeTable_rateNumberTenthTime",type: UNSIGNED, size: 16,address: { read: "81F2", write: "" } },
  { name: "thirdTimeTable_startTimeTenthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F3", write: "" } },
  { name: "thirdTimeTable_rateNumberEleventhTime",type: UNSIGNED, size: 16,address: { read: "81F4", write: "" } },
  { name: "thirdTimeTable_startTimeEleventhTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F5", write: "" } },
  { name: "thirdTimeTable_rateNumberTwelfthTime",type: UNSIGNED, size: 16,address: { read: "81F6", write: "" } },
  { name: "thirdTimeTable_startTimeTwelfthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F7", write: "" } },
  { name: "thirdTimeTable_rateNumberThirteenthTime",type: UNSIGNED, size: 16,address: { read: "81F8", write: "" } },
  { name: "thirdTimeTable_startTimeThirteenthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F9", write: "" } },
  { name: "thirdTimeTable_rateNumberFourteenthTime",type: UNSIGNED, size: 16,address: { read: "81FA", write: "" } },
  { name: "thirdTimeTable_startTimeFourteenthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81FB", write: "" } },  
  // 第四套时段表
  { name: "fourthTimeTable_rateNumberFirstTime",type: UNSIGNED, size: 16,address: { read: "81E0", write: "" } },
  { name: "fourthTimeTable_startTimeFirstTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81E1", write: "" } },
  { name: "fourthTimeTable_rateNumberSecondTime",type: UNSIGNED, size: 16,address: { read: "81E2", write: "" } },
  { name: "fourthTimeTable_startTimeSecondTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81E3", write: "" } },
  { name: "fourthTimeTable_rateNumberThirdTime",type: UNSIGNED, size: 16,address: { read: "81E4", write: "" } },
  { name: "fourthTimeTable_startTimeThirdTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81E5", write: "" } },
  { name: "fourthTimeTable_rateNumberFourthTime",type: UNSIGNED, size: 16,address: { read: "81E6", write: "" } },
  { name: "fourthTimeTable_startTimeFourthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81E7", write: "" } },
  { name: "fourthTimeTable_rateNumberFifthTime",type: UNSIGNED, size: 16,address: { read: "81E8", write: "" } },
  { name: "fourthTimeTable_startTimeFifthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81E9", write: "" } },
  { name: "fourthTimeTable_rateNumberSixthTime",type: UNSIGNED, size: 16,address: { read: "81EA", write: "" } },
  { name: "fourthTimeTable_startTimeSixthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81EB", write: "" } },
  { name: "fourthTimeTable_rateNumberSeventhTime",type: UNSIGNED, size: 16,address: { read: "81EC", write: "" } },
  { name: "fourthTimeTable_startTimeSeventhTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81ED", write: "" } },
  { name: "fourthTimeTable_rateNumberEighthTime",type: UNSIGNED, size: 16,address: { read: "81EE", write: "" } },
  { name: "fourthTimeTable_startTimeEighthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81EF", write: "" } },
  { name: "fourthTimeTable_rateNumberNinthTime",type: UNSIGNED, size: 16,address: { read: "81F0", write: "" } },
  { name: "fourthTimeTable_startTimeNinthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F1", write: "" } },
  { name: "fourthTimeTable_rateNumberTenthTime",type: UNSIGNED, size: 16,address: { read: "81F2", write: "" } },
  { name: "fourthTimeTable_startTimeTenthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F3", write: "" } },
  { name: "fourthTimeTable_rateNumberEleventhTime",type: UNSIGNED, size: 16,address: { read: "81F4", write: "" } },
  { name: "fourthTimeTable_startTimeEleventhTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F5", write: "" } },
  { name: "fourthTimeTable_rateNumberTwelfthTime",type: UNSIGNED, size: 16,address: { read: "81F6", write: "" } },
  { name: "fourthTimeTable_startTimeTwelfthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F7", write: "" } },
  { name: "fourthTimeTable_rateNumberThirteenthTime",type: UNSIGNED, size: 16,address: { read: "81F8", write: "" } },
  { name: "fourthTimeTable_startTimeThirteenthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81F9", write: "" } },
  { name: "fourthTimeTable_rateNumberFourteenthTime",type: UNSIGNED, size: 16,address: { read: "81FA", write: "" } },
  { name: "fourthTimeTable_startTimeFourteenthTimeHourMinute",type: UNSIGNED, size: 16,address: { read: "81FB", write: "" } },  
  // 温度
  { name: "t1Temperature",type: UNSIGNED, size: 16,address: { read: "8200", write: "" } },
  { name: "t2Temperature",type: UNSIGNED, size: 16,address: { read: "8201", write: "" } },
  { name: "t3Temperature",type: UNSIGNED, size: 16,address: { read: "8202", write: "" } },
  // 分次谐波数据
  { name: "totalVoltageDistortionAphase",type: UNSIGNED, size: 16,address: { read: "8206", write: "" } },
  { name: "voltage2To31HarmonicAphase",type: UNSIGNED, size: 16,address: { read: "8207", write: "" } },
  { name: "totalVoltageDistortionBphase",type: UNSIGNED, size: 16,address: { read: "8208", write: "" } },
  { name: "voltage2To31HarmonicBphase",type: UNSIGNED, size: 16,address: { read: "8209", write: "" } },
  { name: "totalVoltageDistortionCphase",type: UNSIGNED, size: 16,address: { read: "820A", write: "" } },
  { name: "voltage2To31HarmonicCphase",type: UNSIGNED, size: 16,address: { read: "820B", write: "" } },
  { name: "totalCurrentDistortionAphase",type: UNSIGNED, size: 16,address: { read: "820C", write: "" } },
  { name: "current2To31HarmonicAphase",type: UNSIGNED, size: 16,address: { read: "822B", write: "" } },
  { name: "totalCurrentDistortionBphase",type: UNSIGNED, size: 16,address: { read: "824A", write: "" } },
  { name: "current2To31HarmonicBphase",type: UNSIGNED, size: 16,address: { read: "8269", write: "" } },
  { name: "totalCurrentDistortionCphase",type: UNSIGNED, size: 16,address: { read: "8288", write: "" } },
  { name: "current2To31HarmonicCphase",type: UNSIGNED, size: 16,address: { read: "82A7", write: "" } },
  // 预留数据
  { name: "fundamentalVoltageAPhase",type: UNSIGNED, size: 16,address: { read: "82E5", write: "" } },
  { name: "fundamentalVoltageBPhase",type: UNSIGNED, size: 16,address: { read: "82E6", write: "" } },
  { name: "fundamentalVoltageCPhase",type: UNSIGNED, size: 16,address: { read: "82E7", write: "" } },
  { name: "harmonicVoltageAPhase",type: UNSIGNED, size: 16,address: { read: "82E8", write: "" } },
  { name: "harmonicVoltageBPhase",type: UNSIGNED, size: 16,address: { read: "82E9", write: "" } },
  { name: "harmonicVoltageCPhase",type: UNSIGNED, size: 16,address: { read: "82EA", write: "" } },
  { name: "fundamentalCurrentAPhase",type: UNSIGNED, size: 16,address: { read: "82EB", write: "" } },
  { name: "fundamentalCurrentBPhase",type: UNSIGNED, size: 16,address: { read: "82EC", write: "" } },
  { name: "fundamentalCurrentCPhase",type: UNSIGNED, size: 16,address: { read: "82ED", write: "" } },
  { name: "harmonicCurrentAPhase",type: UNSIGNED, size: 16,address: { read: "82EE", write: "" } },
  { name: "harmonicCurrentBPhase",type: UNSIGNED, size: 16,address: { read: "82EF", write: "" } },
  { name: "harmonicCurrentCPhase",type: UNSIGNED, size: 16,address: { read: "82F0", write: "" } },
  { name: "fundamentalActivePowerAPhase",type: UNSIGNED, size: 16,address: { read: "82F1", write: "" } },
  { name: "fundamentalActivePowerBPhase",type: UNSIGNED, size: 16,address: { read: "82F2", write: "" } },
  { name: "fundamentalActivePowerCPhase",type: UNSIGNED, size: 16,address: { read: "82F3", write: "" } },
  { name: "totalFundamentalActivePower",type: UNSIGNED, size: 16,address: { read: "82F4", write: "" } },
  { name: "fundamentalReactivePowerAPhase",type: UNSIGNED, size: 16,address: { read: "82F5", write: "" } },
  { name: "fundamentalReactivePowerBPhase",type: UNSIGNED, size: 16,address: { read: "82F6", write: "" } },
  { name: "fundamentalReactivePowerCPhase",type: UNSIGNED, size: 16,address: { read: "82F7", write: "" } },
  { name: "totalFundamentalReactivePower",type: UNSIGNED, size: 16,address: { read: "82F8", write: "" } },
  { name: "harmonicActivePowerAPhase",type: UNSIGNED, size: 16,address: { read: "82F9", write: "" } },
  { name: "harmonicActivePowerBPhase",type: UNSIGNED, size: 16,address: { read: "82FA", write: "" } },
  { name: "harmonicActivePowerCPhase",type: UNSIGNED, size: 16,address: { read: "82FB", write: "" } },
  { name: "totalHarmonicActivePower",type: UNSIGNED, size: 16,address: { read: "82FC", write: "" } },
  { name: "harmonicReactivePowerAPhase",type: UNSIGNED, size: 16,address: { read: "82FD", write: "" } },
  { name: "harmonicReactivePowerBPhase",type: UNSIGNED, size: 16,address: { read: "82FE", write: "" } },
  { name: "harmonicReactivePowerCPhase",type: UNSIGNED, size: 16,address: { read: "82FF", write: "" } },
  { name: "totalHarmonicReactivePower",type: UNSIGNED, size: 16,address: { read: "8300", write: "" } },  
];

export const getObjByAddress = (val) => {
  val = val.toUpperCase();
  for (let obj of NameAddress) {
    if (obj.address.read.toUpperCase() === val) {
      return obj;
    }
  }

  return {};
}

export const getObjByName = (val) => {
  for (let obj of NameAddress) {
    if (obj.name === val) {
    return obj;
  }
}

return {};
}

export const RunState = [
  {
    label: "正常（Rack 可以放电, 可以充电）",
    value: "0"
  },
  {
    label: "禁充（Rack 可以放电, 不能充电）",
    value: "1"
  },
  {
    label: "禁放（Rack 可以充电, 不能放电）",
    value: "2"
  },
  {
    label: "待机（Rack 不能充放）",
    value: "3"
  },
  {
    label: "停机（Rack 不能充放）",
    value: "4"
  }
]

export const getRunState = (val) => {
  for (let obj of RunState) {
    if (obj.value === val) {
    return obj.label;
  }
}

return "无数据";
}

export const PrechargePhase = [
  {
    label: "断网（主正、主负、预充断开）",
    value: "0"
  },
  {
    label: "启动并网（预充闭合、主负，断开主正）",
    value: "1"
  },
  {
    label: "并网中（预充和主正、主负闭合）",
    value: "2"
  },
  {
    label: "并网成功（预充断开，主正、主负闭合）",
    value: "3"
  },
  {
    label: "并网失败",
    value: "4"
  }
];

export const getPrechargePhase = (val) => {
  for (let obj of PrechargePhase) {
    if (obj.value === val) {
    return obj.label;
  }
}

return "无数据";
}

export const ConnectionState = [
  {
    label: "断开",
    value: "0"
  },
  {
    label: "主接触状态;",
    value: "1"
  },
  {
    label: "预充接触器状态",
    value: "2"
  },
  {
    label: "隔离开关状态",
    value: "4"
  }
];

export const getConnectionState = (val) => {
  for (let obj of ConnectionState) {
    if (obj.value === val) {
      return obj.label;
    }
  }

  return "无数据";
}

export const Fault = [
  {
    label: "正常",
    value: "0"
  },
  {
    label: "BMU 硬件故障",
    value: "1"
  },
  {
    label: "BCU 硬件故障",
    value: "2"
  },
  {
    label: "熔断器故障",
    value: "3"
  },
  {
    label: "接触器粘连故障",
    value: "4"
  },
  {
    label: "BMU 通信故障",
    value: "5"
  },
  {
    label: "电流传感器故障",
    value: "6"
  },
  {
    label: "绝缘监测设备故障",
    value: "7"
  },
  {
    label: "隔开开关异常断开",
    value: "8"
  }
];


export const getFault = (val) => {
  for (let obj of Fault) {
    if (obj.value === val) {
      return obj.label;
    }
  }

  return "无数据";
}

export const Warning = [
  {
    label: "正常",
    value: "0"
  },
  {
    label: "总电压过压一级报警",
    value: "0"
  },
  {
    label: "总电压欠压一级报警",
    value:  Math.pow(2, 0).toString()
  },
  {
    label: "单体过压一级报警",
    value:  Math.pow(2, 1).toString()
  },
  {
    label: "单体欠压一级报警",
    value:  Math.pow(2, 2).toString()
  },
  {
    label: "放电电流过大一级报警",
    value:  Math.pow(2, 3).toString()
  },
  {
    label: "充电电流过一级报警",
    value:  Math.pow(2, 5).toString()
  },
  {
    label: "放电电池过温一级报警",
    value:  Math.pow(2, 6).toString()
  },
  {
    label: "放电电池欠温一级报警",
    value:  Math.pow(2, 7).toString()
  },
  {
    label: "充电电池过温一级报警",
    value:  Math.pow(2, 8).toString()
  },
  {
    label: "充电电池欠温一级报警",
    value:  Math.pow(2, 9).toString()
  },
  {
    label: "绝缘阻值过低一级报警",
    value:  Math.pow(2, 10).toString()
  },
  {
    label: "极柱温度过高一级报警",
    value:  Math.pow(2, 11).toString()
  },
  {
    label: "高压箱连接器温度过高一级报警",
    value:  Math.pow(2, 12).toString()
  },
  {
    label: "单体压差过高一级报警",
    value:  Math.pow(2, 13).toString()
  },
  {
    label: "单体温差过高一级报警",
    value:  Math.pow(2, 14).toString()
  },
  {
    label: "SOC低一级报警",
    value: Math.pow(2, 15).toString()
  }
];

export const getWarning = (val) => {
  for (let obj of Warning) {
    if (obj.value === val) {
      return obj.label;
    }
  }

  return "无数据";
}

export const Alarm = [
  {
    label: "正常",
    value: "0"
  },
  {
    label: "总电压过压二级报警",
    value: Math.pow(2, 0).toString()
  },
  {
    label: "总电压欠压二级报警",
    value: Math.pow(2, 1).toString()
  },
  {
    label: "单体过压二级报警",
    value: Math.pow(2, 2).toString()
  },
  {
    label: "单体欠压二级报警",
    value: Math.pow(2, 3).toString()
  },
  {
    label: "放电电流过大二级报警",
    value: Math.pow(2, 4).toString()
  },
  {
    label: "充电电流过二级报警",
    value: Math.pow(2, 5).toString()
  },
  {
    label: "放电电池过温二级报警",
    value: Math.pow(2, 6).toString()
  },
  {
    label: "放电电池欠温二级报警",
    value: Math.pow(2, 7).toString()
  },
  {
    label: "充电电池过温二级报警",
    value: Math.pow(2, 8).toString()
  },
  {
    label: "充电电池欠温二级报警",
    value: Math.pow(2, 9).toString()
  },
  {
    label: "绝缘阻值过低二级报警",
    value: Math.pow(2, 10).toString()
  },
  {
    label: "极柱温度过高二级报警",
    value: Math.pow(2, 11).toString()
  },
  {
    label: "高压箱连接器温度过高二级报警",
    value: Math.pow(2, 12).toString()
  },
  {
    label: "单体压差过高二级报警",
    value: Math.pow(2, 13).toString()
  },
  {
    label: "单体温差过高二级报警",
    value: Math.pow(2, 14).toString()
  },
  {
    label: "SOC低二级报警",
    value: Math.pow(2, 15).toString()
  }
];

export const getAlarm = (val) => {
  for (let obj of Alarm) {
    if (obj.value === val) {
      return obj.label;
    }
  }

  return "无数据";
}

export const CriticalAlarm = [
  {
    label: "正常",
    value: "0"
  },
  {
    label: "总电压过压三级报警",
    value: Math.pow(2, 0).toString()
  },
  {
    label: "总电压欠压三级报警",
    value: Math.pow(2, 1).toString()
  },
  {
    label: "单体过压三级报警",
    value: Math.pow(2, 2).toString()
  },
  {
    label: "单体欠压三级报警",
    value: Math.pow(2, 3).toString()
  },
  {
    label: "放电电流过大三级报警",
    value: Math.pow(2, 4).toString()
  },
  {
    label: "充电电流过三级报警",
    value: Math.pow(2, 5).toString()
  },
  {
    label: "放电电池过温三级报警",
    value: Math.pow(2, 6).toString()
  },
  {
    label: "放电电池欠温三级报警",
    value: Math.pow(2, 7).toString()
  },
  {
    label: "充电电池过温三级报警",
    value: Math.pow(2, 8).toString()
  },
  {
    label: "充电电池欠温三级报警",
    value: Math.pow(2, 9).toString()
  },
  {
    label: "绝缘阻值过低三级报警",
    value: Math.pow(2, 10).toString()
  },
  {
    label: "极柱温度过高三级报警三级报警",
    value: Math.pow(2, 11).toString()
  },
  {
    label: "高压箱连接器温度过高三级报警",
    value: Math.pow(2, 12).toString()
  },
  {
    label: "单体压差过高三级报警",
    value: Math.pow(2, 13).toString()
  },
  {
    label: "单体温差过高三级报警",
    value: Math.pow(2, 14).toString()
  },
  {
    label: "SOC低三级报警",
    value: Math.pow(2, 15).toString()
  }
];

export const getCriticalAlarm = (val) => {
  for (let obj of CriticalAlarm) {
    if (obj.value === val) {
      return obj.label;
    }
  }

  return "无数据";
}

export const ChargeDischargeState = [
  {
    label: "静置",
    value: "0"
  },
  {
    label: "放电",
    value: "1"
  },
  {
    label: "充电",
    value: "2"
  }
];

export const getChargeDischargeState = (val) => {
  for (let obj of ChargeDischargeState) {
    if (obj.value === val) {
      return obj.label;
    }
  }

  return "无数据";
}

export const Pb_Error1 = [
  {
    label: "AFE芯片故障",
    value: Math.pow(2, 0).toString()
  },
  {
    label: "断线故障",
    value: Math.pow(2, 1).toString()
  },
  {
    label: "AFE报PCB过温故障",
    value: Math.pow(2, 2).toString()
  },
  {
    label: "AFE报电池不匹配",
    value: Math.pow(2, 3).toString()
  },
  {
    label: "反接保护故障",
    value: Math.pow(2, 4).toString()
  },
  {
    label: "NTC未接故障",
    value: Math.pow(2, 5).toString()
  },
  {
    label: "加热故障",
    value: Math.pow(2, 6).toString()
  },
  {
    label: "制冷故障",
    value: Math.pow(2, 7).toString()
  },
  {
    label: "蜂鸣器故障",
    value: Math.pow(2, 8).toString()
  }
];

export const getPbError1 = (val) => {
  for (let obj of Pb_Error1) {
    if (obj.value === val) {
      return obj.label;
    }
  }

  return "无故障";
}

export const Pb_Error2 = [
  {
    label: "单体过压保护",
    value: Math.pow(2, 0).toString()
  },
  {
    label: "单体欠压保护",
    value: Math.pow(2, 1).toString()
  },
  {
    label: "压差过大保护",
    value: Math.pow(2, 2).toString()
  },
  {
    label: "电池失效保护",
    value: Math.pow(2, 3).toString()
  },
  {
    label: "充电电池过温保护",
    value: Math.pow(2, 4).toString()
  },
  {
    label: "放电电池过温保护",
    value: Math.pow(2, 5).toString()
  },
  {
    label: "放电电池欠温保护",
    value: Math.pow(2, 6).toString()
  },
  {
    label: "电池温差过大保护",
    value: Math.pow(2, 7).toString()
  },
  {
    label: "总压过压保护",
    value: Math.pow(2, 8).toString()
  },
  {
    label: "总压欠压保护",
    value: Math.pow(2, 9).toString()
  },
  {
    label: "SOC 过低保护",
    value: Math.pow(2, 10).toString()
  },
  {
    label: "放电短路保护",
    value: Math.pow(2, 11).toString()
  },
  {
    label: "充电短路保护",
    value: Math.pow(2, 12).toString()
  },
  {
    label: "总压过压保护",
    value: Math.pow(2, 13).toString()
  },
  {
    label: "放电过流1保护",
    value: Math.pow(2, 14).toString()
  },
  {
    label: "放电过流2保护",
    value: Math.pow(2, 15).toString()
  },
];

export const getPbError2 = (val) => {
  for (let obj of Pb_Error2) {
    if (obj.value === val) {
      return obj.label;
    }
  }

  return "无故障";
}

export const Pb_Error3 = [
  {
    label: "充电过流保护",
    value: Math.pow(2, 0).toString()
  },
  {
    label: "MOS 过温保护",
    value: Math.pow(2, 1).toString()
  },
  {
    label: "限流过温保护",
    value: Math.pow(2, 2).toString()
  },
  {
    label: "电池温差过大保护",
    value: Math.pow(2, 7).toString()
  },
  {
    label: "单体过压告警",
    value: Math.pow(2, 8).toString()
  },
  {
    label: "单体欠压告警",
    value: Math.pow(2, 9).toString()
  },
  {
    label: "压差过大告警",
    value: Math.pow(2, 10).toString()
  },
  {
    label: "电池失效告警",
    value: Math.pow(2, 11).toString()
  },
  {
    label: "充电电池过温告警",
    value: Math.pow(2, 12).toString()
  },
  {
    label: "放电电池过温告警",
    value: Math.pow(2, 13).toString()
  },
  {
    label: "充电电池欠温告警",
    value: Math.pow(2, 14).toString()
  },
  {
    label: "放电电池欠温告警",
    value: Math.pow(2, 15).toString()
  }
];

export const getPbError3 = (val) => {
  for (let obj of Pb_Error3) {
    if (obj.value === val) {
      return obj.label;
    }
  }

  return "无故障";
}

export const Pb_Error4 = [
  {
    label: "电池温差过大告警",
    value: Math.pow(2, 0).toString()
  },
  {
    label: "总压过压告警",
    value: Math.pow(2, 1).toString()
  },
  {
    label: "总压欠压告警",
    value: Math.pow(2, 2).toString()
  },
  {
    label: "SOC 过低告警",
    value: Math.pow(2, 3).toString()
  },
  {
    label: "均衡1过温告警",
    value: Math.pow(2, 4).toString()
  },
  {
    label: "均衡2过温告警",
    value: Math.pow(2, 5).toString()
  }
];

export const getPbError4 = (val) => {
  for (let obj of Pb_Error4) {
    if (obj.value === val) {
      return obj.label;
    }
  }

  return "无故障";
}

export const RackWarningSummary = [
  "总电压过压一级报警汇总",
  "总电压欠压一级报警汇总",
  "单体过压一级报警汇总",
  "单体欠压一级报警汇总",
  "放电电流过大一级报警汇总",
  "充电电流过大一级报警汇总",
  "放电电池过温一级报警汇总",
  "放电电池欠温一级报警汇总",
  "充电电池过温一级报警汇总",
  "充电电池欠温一级报警汇总",
  "绝缘阻值过低一级报警汇总",
  null,
  "高压箱连接器温度过高一级报警汇总",
  "单体压差过高一级报警汇总",
  "单体温差过高一级报警汇总",
  "SOC低一级报警汇总"
];

export const RackAlarmSummary = [
  "总电压过压二级报警汇总",
  "总电压欠压二级报警汇总",
  "单体过压二级报警汇总",
  "单体欠压二级报警汇总",
  "放电电流过大二级报警汇总",
  "充电电流过大二级报警汇总",
  "放电电池过温二级报警汇总",
  "放电电池欠温二级报警汇总",
  "充电电池过温二级报警汇总",
  "充电电池欠温二级报警汇总",
  "绝缘阻值过低二级报警汇总",
  null,
  "高压箱连接器温度过高二级报警汇总",
  "单体压差过高二级报警汇总",
  "单体温差过高二级报警汇总",
  "SOC低二级报警汇总"
];

export const RackCriticalAlarmSummary = [
  "总电压过压三级报警汇总",
  "总电压欠压三级报警汇总",
  "单体过压三级报警汇总",
  "单体欠压三级报警汇总",
  "放电电流过大三级报警汇总",
  "充电电流过大三级报警汇总",
  "放电电池过温三级报警汇总",
  "放电电池欠温三级报警汇总",
  "充电电池过温三级报警汇总",
  "充电电池欠温三级报警汇总",
  "绝缘阻值过低三级报警汇总",
  "高压箱连接器温度过高三级报警汇总",
  null,
  "单体压差过高三级报警汇总",
  "单体温差过高三级报警汇总",
  "SOC低三级报警汇总"
];

const getSummary = (val, summary) => {
  if (val == "0") {
    return "正常";
  }

  val = binaryAppendZero(decChangeScale(val, 2), 16);
  val = String.reverse(val.toString());

  const output = [];
  for (let i = 0, len = val.length; i < len; i++) {
    if (val[i] === "1") {
      output.push(summary[i]);
    }
  }

  return output.join();
}

export const getRackWarningSummary = (val) => {
  return getSummary(val, RackWarningSummary);
}

export const getRackAlarmSummary = (val) => {
  return getSummary(val, RackAlarmSummary);
}
export const getRackCriticalAlarmSummary = (val) => {
  return getSummary(val, RackCriticalAlarmSummary);
}

export default {
  NameAddress,
  getObjByAddress,
  getObjByName,
  RunState,
  getRunState,
  PrechargePhase,
  getPrechargePhase,
  ConnectionState,
  getConnectionState,
  Fault,
  getFault,
  Warning,
  getWarning,
  Alarm,
  getAlarm,
  CriticalAlarm,
  getCriticalAlarm,
  ChargeDischargeState,
  getChargeDischargeState,
  getPbError1,
  getPbError2,
  getPbError3,
  getPbError4,
  RackWarningSummary,
  getRackWarningSummary,
  RackAlarmSummary,
  getRackAlarmSummary,
  RackCriticalAlarmSummary,
  getRackCriticalAlarmSummary
}