import { getObjByAddress, getObjByName } from "@/utils/address-configs";
import { systemConfig, getDevicesDetail, editDevices } from "@/api/device";
const { calcHexFromUnsignedDec, calcUnsignedDecFromHex, hexToBinary } = $numberMatrixing;

const getDeviceDetail = async (that) => {
  try{
    const res = await getDevicesDetail(that.deviceInfo.code);
    that.deviceInfo = Object.assign(that.deviceInfo, res);
  } finally {
  }
}

const postDeviceDetail = async (that) => {
  try {
    that.deviceInfo.deviceGroup = Array.isArray(that.deviceInfo.deviceGroup) ? that.deviceInfo.deviceGroup.join() : that.deviceInfo.deviceGroup;
    const deviceInfo = Objcet.assign({}, that.deviceInfo);
    deviceInfo.systeminfo = null;
    await editDevices(that.deviceInfo.id, deviceInfo);
  } finally {
  }
}

export const checkResult = async (that, res, params, callback) => {

  let output = String(res);
  let flag = false;
  that.configDisabled = true;
  params = params || {};

  const type = params.type;
  const error = params.error !== false ? true : false;
  const success = params.success !== false ? true : false;
  const before = params.before || false;
  const after = params.before || false;

  switch (output) {
    case "00": // 成功
      flag = true;
      if (success) {
        that.$message.success("数据已下发至网关！");
      }
      break;
    case "01": // 到 99 之前都是设备返回的错误
      if (error) {
        that.$message.error("非法功能");
      }
      break;
    case "02":
      if (error) {
        that.$message.error("非法地址");
      }
      break;
    case "03":
      if (error) {
        that.$message.error("非法数据");
      }
      break;
    case "04":
      if (error) {
        that.$message.error("从站设备故障");
      }
      break;
    case "05":
      if (error) {
        that.$message.error("长度错误");
      }
      break;
    case "06":
      if (error) {
        that.$message.error("读取写入 ID 不一致");
      }
      break;
    case "07":
      if (error) {
        that.$message.error("数据超时");
      }
      break;
    case "08":
      if (error) {
        that.$message.error("CAN 发送失败");
      }
      break;
    case "99": // 服务器超时
      if (error) {
        that.$message.warning("设备数据正在上传，请稍后再试");
      }
      break;
    default:
      if (type === "read") {
        that.$message.success("数据读取成功");
      }
      that.configDisabled = false;
      flag = true;
      break;
  }

  if (before && flag) { // TODO 这里是否要加入 read / write 判断，再议
    // 每次操作数据后，都从数据库里同步一下设备的信息
    /*
      注意：放在前面，就表示不需要对数据进行同步操作
      而在后面，则表示需要对数据进行同步（到数据库的）操作，甚至需要回调函数的后续结果作为前提
    */
    getDeviceDetail(that); // TODO 这里有个问题，是从数据库同步，还是同步到数据库里去
  }

  if (callback && typeof callback === "function") {
    await callback(flag);
  }

  if (after && flag) {
    postDeviceDetail(that);
  }
}

export const getAdressByName = (names, type = "read") => {
  const addrs = [];
  for (const name of names) {
    const { address } = getObjByName(name);
    if (!address) continue; // 在没有配置的时候（写错或者其他原因），直接略过
    const addr = address[type];
    if (!addr) continue; // 在没有配置的时候，直接略过
    addrs.push(address[type]);
  }
  return addrs;
}

export const readSystemConfig = async (that, pointers, masterID, type) => {

  masterID = (masterID || "00").toUpperCase();
  type = type || "read";

  return new Promise(async (resolve, reject) => {
    try {
      const price = getAdressByName(pointers, type);

      const params = {
        code: that.deviceInfo.code,
        masterID,
        price: price.join("")
      };

      const res = await systemConfig.read(params);
      resolve(res);
    } catch (err) {
      that.$message.error("设备信息读取失败");
      reject(err);
    } finally {
      that.$forceUpdate();
    }
  });
}

export const addrValToObject = (str) => {
  const systemData = {};

  str = String(str);

  if (!str) {
    return systemData;
  }

  str = str.toUpperCase();

  for (let i = 0, len = str.length; i < len; i += 8) {
    const address = str.slice(i, i + 4);
    let { name, type, length } = getObjByAddress(address);
    if (!name) continue;
    const method = type ? "calcUnsignedDecFromHex": "calcSignedDecFromHex";  // true: unsigned , false signed
    length = length || 16;
    const val = $numberMatrixing[method](str.slice(i + 4, i + 8), length);
    systemData[name] = (val).toString();
  }

  // 从读取到的masterId 数量来获得之后的 masterid的具体值
  const start = 0x2818;
  if (systemData.masterCnts) {
    for (let i = 0, len = systemData.masterCnts; i < len; i++) {
      let addr = calcHexFromUnsignedDec(start + i);
      addr = addr.toUpperCase();
      let index = str.indexOf(addr);
      if (index >= 0) {
        const masterID = str.slice(index + 6, index + 8); // 因为 masterID 是两位的，所以这里截取后两位
        systemData[`masterID${i}`] = masterID;
      }
    }
  }

  return systemData;
}
export const addrValToObject2 = (str) => {
  const systemData = {};

  if (!str) {
    return systemData;
  }

  for (let i = 0, len = str.length; i < len; i += 8) {
    const { name, type } = getObjByAddress(str.slice(i, i + 4));
    if (!name) continue;
    const val = hexToBinary(str.slice(i + 4, i + 8), 4);
    systemData[name] = (val).toString();
  }

  return systemData;
}

export const objectToAddrVal = (obj) => {
  const output = [];
  for (const key in obj) {
    const {address, type } = getObjByName(key);
    const method = type ? "calcHexFromUnsignedDec" : "calcHexFromSignedDec";
    if (address && address.write) {
      if (address.write && obj[key] !== undefined) {
        output.push(address.write, $numberMatrixing[method](obj[key]));
      }
    }
  }
  return output.join("");
}

// export const parseWarningData = (warning) => {

//   const warn = String.reverse(warning.slice(0, 32).slice(8));;
//   const alarm = String.reverse(warning.slice(32, 64).slice(8));
//   const criticalAlarm = String.reverse(warning.slice(64).slice(8));

//   const warningData = new Array(warn.length + alarm.length + criticalAlarm.length).fill(false);
//   let count = 0;

//   for (let i = 0, len = warn.length; i < len; i++) {
//     const flg = warn[i] === "1";
//     if (flg) {
//       warningData[i * 3] = flg;
//       count++;
//     }
//   }
//   for (let i = 0, len = alarm.length; i < len; i++) {
//     const flg = alarm[i] === "1";
//     if (flg) {
//       warningData[i * 3 + 1] = flg;
//       count++;
//     }
//   }
//   for (let i = 0, len = criticalAlarm.length; i < len; i++) {
//     const flg = criticalAlarm[i] === "1";
//     if (flg) {
//       warningData[i * 3 + 2] = flg;
//       count++;
//     }
//   }

//   return { warningData, count };
// }

export const calcMasterID = (val) => {
  const masterId = calcHexFromUnsignedDec(val, 2);
  return masterId;
}

export const getMasterIDObject = (val) => {

  if (!val) {
    return {
      address: [],
      count: 0
    }
  }

  const index = val.indexOf("2817"); // 主控开始地址
  const res = val.slice(index, index + 32 * 8) || 0;

  const masters = [];
  const count = calcUnsignedDecFromHex(res.slice(4, 8)) || 0;

  for (let i = 1; i < count + 1; i++) {
    const tmp = res.slice(i * 8, (i + 1) * 8);
    const address = tmp.slice(0, 4);
    const value = calcUnsignedDecFromHex(tmp.slice(4));

    masters.push({
      address,
      value
    });
  }

  return { masters, count };
}

export default {
  checkResult,
  addrValToObject,
  addrValToObject2,
  getAdressByName,
  readSystemConfig,
  objectToAddrVal,
  calcMasterID,
  getMasterIDObject
}